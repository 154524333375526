import * as d3 from "d3";

// Copyright 2021-2023 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/tree
function FlareTree(data, verticalSpacing, { // data is either tabular (array of objects) or hierarchy (nested objects)
    path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id = Array.isArray(data) ? d => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId = Array.isArray(data) ? d => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children, // if hierarchical data, given a d in data, returns its children
    tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label, // given a node d, returns the display name
    title, // given a node d, returns its hover text
    link, // given a node d, its link (if any)
    linkTarget = "_blank", // the target attribute for links (if any)
    width, // outer width, in pixels
    height = 300, // outer height, in pixels
    r = 13, // radius of nodes
    padding = 1, // horizontal padding for first and last column
    fill = "#999", // fill for nodes
    fillOpacity, // fill opacity for nodes
    stroke = "#555", // stroke for links
    strokeWidth = 5, // stroke width for links
    strokeOpacity = 0.7, // stroke opacity for links
    strokeLinejoin, // stroke line join for links
    strokeLinecap, // stroke line cap for links
    halo = "#FFF8E7", // color of label halo 
    haloWidth = 3, // padding around the labels
    curve = d3.curveBumpY, // curve for the link
    onNodeHover,
} = {}) {

    // If id and parentId options are specified, or the path option, use d3.stratify
    // to convert tabular data to a hierarchy; otherwise we assume that the data is
    // specified as an object {children} with nested objects (a.k.a. the “flare.json”
    // format), and use d3.hierarchy.
    const root = path != null ? d3.stratify().path(path)(data)
        : id != null || parentId != null ? d3.stratify().id(id).parentId(parentId)(data)
            : d3.hierarchy(data, children);

    if (sort != null) root.sort(sort);

    const dy = 80; // Adjusted for horizontal spacing
    const dx = verticalSpacing; // Adjusted for vertical spacing
    tree().nodeSize([dy, dy])(root);

    let x0 = Infinity;
    let x1 = -Infinity;
    let y0 = Infinity;
    let y1 = -Infinity;
    root.each(d => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
        if (d.y > y1) y1 = d.y;
        if (d.y < y0) y0 = d.y;
    });

    // Dynamically calculate height based on the layout, if not predefined
    height = height === undefined ? (y1 - y0 + root.height * dy) : height;


    // Flip the y-axis for the nodes to grow the tree upwards
    root.each(d => {
        d.y = height - (d.depth * dx); // Adjust y-position based on depth to flip the tree
    });

    if (width === undefined) width = x1 - x0 + dy * 2 + 100;

    const svg = d3.create("svg")
        .attr("viewBox", [x0 - dy / 2, y0 - dy / 2, x1 - x0 + dy, height + dy]) // Adjust viewBox to fit the tree
        .attr("width", width)
        .attr("height", height + dy) // Add some padding to height
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .attr("font-size", 14);

    const linkGenerator = d3.linkVertical()
        .x(d => d.x)
        .y(d => d.y);

    svg.append("g")
        .attr("fill", "none")
        .attr("stroke-opacity", strokeOpacity)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("stroke-width", strokeWidth)
        .selectAll("path")
        .data(root.links())
        .join("path")
        .attr("stroke", d => d.source.data.stroke)
        .attr("d", linkGenerator); // Use vertical link generator with inverted y-values

    const node = svg.append("g")
        .selectAll("a")
        .data(root.descendants())
        .join("a")
        .attr("xlink:href", link == null ? null : d => link(d.data, d))
        .attr("target", link == null ? null : linkTarget)
        .attr("transform", d => `translate(${d.x},${d.y})`);

    node.append("circle")
        .attr("fill", d => d.data.fill)
        .attr("r", r)
        .on("mouseover", function (event, d) {
            d3.select(this).attr("stroke-width", "3").attr("stroke", "grey"); // Change color on hover
            if (onNodeHover) onNodeHover(d.data);
        })
        .on("mouseout", function (event, d) {
            d3.select(this).attr("stroke-width", "0").attr("stroke", "grey"); // Revert color
        });


    if (title != null) node.append("title").text(d => title(d.data, d));


    if (label != null) {
        node.append("text")
            .attr("dy", "-1.4em")
            .attr("x", "-3em")
            .attr("text-anchor", "start")
            .attr("paint-order", "stroke")
            .attr("stroke", halo)
            .attr("stroke-width", haloWidth)
            .text((d, i) => label(d.data, d));
    }

    return svg.node();
}
export default FlareTree;