import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


function CycleProgressBar({ story, sameRow = true }) {
    const location = useLocation()
    const [now, setNow] = useState(dayjs.utc());
    const [creationDate] = useState(dayjs.utc(story.creation_date_downtime));
    const [endDate] = useState(dayjs.utc(story.end_date));
    const [progress, setProgress] = useState(0);
    const [minutesRemaining, setMinutesRemaining] = useState("");
    const [color, setColor] = useState("success");
    const colSize = sameRow ? 10 : 12;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(dayjs());
        }, 1000); // update current time every second

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const totalDuration = story.writing_period + story.voting_period; // total duration of one cycle in minutes

        const diff = (now - creationDate) / 60000; // difference in minutes
        const cyclePos = diff % totalDuration; // position in the current cycle in minutes

        if (cyclePos <= story.writing_period) {
            setColor("write");
            setProgress((cyclePos / story.writing_period) * 100);
            setMinutesRemaining(String(Math.round((story.writing_period - cyclePos) * 100) / 100) + "/" + String(story.writing_period) + " min");
        } else {
            setColor("vote");
            setProgress(((cyclePos - story.writing_period) / story.voting_period) * 100);
            setMinutesRemaining(String(Math.round((story.writing_period + story.voting_period - cyclePos) * 100) / 100) + "/" + String(story.voting_period) + " min");
        }
    }, [now, story, creationDate]);

    return (
        <>
            {(story.suspension_date !== null && story.is_dead === false) &&
                <div>
                    <Link to={`/story/${story.id}/${story.branch_id}`} state={{ story: story }}>
                        <Button variant='secondary' size='sm'>Suspended story</Button>
                    </Link>
                </div>
            }
            {story.is_dead === true &&
                <div>
                    <Button variant='danger' size='sm' disabled>Dead branch</Button>
                </div>
            }
            {(story.suspension_date === null && story.is_dead === false) &&
                <>
                    {(endDate.isAfter(now) && creationDate.isBefore(now) && story.remaining_cycles > 0) ?
                        <Row>
                            <Col>
                                {(location.pathname !== "/continue" && location.pathname !== "/vote") ?
                                    <Link to={color === 'write' ? "/continue" : "/vote"} state={{ story: story }} style={{ textDecoration: "none" }}>
                                        <div className='d-grid'><Button variant={color} size='sm'>{color === 'write' ? 'Write now' : 'Vote now' + (story.n_votes ? ' (' + story.n_votes + ')' : '')}</Button></div>
                                    </Link>
                                    :
                                    <Link to={`/story/${story.id}/${story.branch_id}`} state={{ story: story }} style={{ textDecoration: "none" }}>
                                        <div className='d-grid'><Button size='sm' variant='outline-primary'>Show story</Button></div>
                                    </Link>
                                }
                            </Col>
                            <Col xs={colSize}><ProgressBar className='mt-2' animated now={progress} variant={color} label={minutesRemaining} /></Col>
                        </Row>
                        :
                        creationDate.isAfter(now) ?
                            <div>
                                <Link to={`/story/${story.id}/${story.branch_id}`} state={{ story: story }}>
                                    <Button variant='write' size='sm'>Scheduled story</Button>

                                </Link>
                            </div>
                            :
                            <div>
                                <Link to={`/story/${story.id}/${story.branch_id}`} state={{ story: story }}>
                                    <Button variant='write' size='sm'>Archived story</Button>
                                </Link>
                            </div>
                    }
                </>
            }
        </>
    );
}

export default CycleProgressBar;