import React, { useEffect, useState, useContext } from 'react';
import { Button, ToggleButton, Col, Container, Row, ButtonGroup } from 'react-bootstrap';
import { urls } from './ressources/urls';
import { RocketTakeoff } from 'react-bootstrap-icons';
import StoryCard from './ressources/StoryCard';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton';
import { useUser } from './contexts/UserContext.tsx';
import Story from './types/types.Story';

type StoryState = "writing" | "voting"
interface SearchValues {
    current_state: StoryState
    user_languages: string[] | undefined;
};

const ExplorePage = () => {
    const { user } = useUser();
    const [stories, setStories] = useState<Array<Story>>([]);
    const [buttonDisabled, setButtonDisbaled] = useState(false);
    const [radioValue, setRadioValue] = useState<StoryState>("writing");

    async function fetchStories(values: SearchValues) {
        setButtonDisbaled(true);
        await trackPromise(fetch(urls.baseUrl + urls.storySearch, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ search_values: values, results_per_page: 8, current_page: 1, explore: true }),
        }))
            .then((response) => response.json())
            .then((data) => {
                setStories(data.stories);
            });
        setButtonDisbaled(false);
    };

    function handleExploreClick(state: StoryState) {
        fetchStories({ current_state: state, user_languages: user?.languages })
    }

    useEffect(() => {
        fetchStories({ current_state: radioValue, user_languages: user?.languages })
    }, []);

    return (
        <div className="ExplorePage">
            <Container fluid className='pt-3'>
                <Row >
                    <div className="d-flex justify-content-center display-6">
                        <span className="text-center themed-text mb-1">Explore stories</span>
                    </div>
                </Row>
                <div className="d-flex justify-content-center">
                    <Row>
                        <div className="display-6">
                            <Col>
                                <div className="align-items-center">
                                    <ButtonGroup>
                                        <ToggleButton
                                            size='lg'
                                            key={1}
                                            id={`radio-1`}
                                            type="radio"
                                            variant='outline-write'
                                            name="radio"
                                            value={'writing'}
                                            checked={radioValue === 'writing'}
                                            onChange={(e) => { setRadioValue('writing'); handleExploreClick('writing') }}
                                        >
                                            Writing
                                        </ToggleButton>
                                        <ToggleButton
                                            size='lg'
                                            key={2}
                                            id={`radio-2`}
                                            type="radio"
                                            variant='outline-vote'
                                            name="radio"
                                            value={'voting'}
                                            checked={radioValue === 'voting'}
                                            onChange={(e) => { setRadioValue('voting'); handleExploreClick('voting') }}
                                        >
                                            Voting
                                        </ToggleButton>
                                    </ButtonGroup>
                                </div>
                            </Col>
                            <Col className="d-flex justify-content-center mt-2">
                                <Button size='lg' variant='explore' disabled={buttonDisabled} onClick={() => handleExploreClick(radioValue)}>
                                    <LoadingIndicatorButton />Explore <RocketTakeoff size={26} />
                                </Button>
                            </Col>
                        </div>
                    </Row>
                </div>
                <Row className='mt-3'>
                    {stories.map(story => (
                        <Col xs={12} lg={4} xl={3} key={story.branch_id} className='d-flex justify-content-center'>
                            <StoryCard story={story} />
                        </Col>
                    ))}
                </Row>
            </Container >
        </div >
    );
};

export default ExplorePage;