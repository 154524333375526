import React, { useEffect, useState } from 'react';
import { PersonCircle } from 'react-bootstrap-icons';
import { useUser } from '../contexts/UserContext.tsx';
import { urls } from './urls';

const MessagesIndicator = () => {
    const { user } = useUser();
    const [hasNewMessages, setHasNewMessages] = useState(false);

    // Function to check for new messages
    const checkForNewMessages = async () => {
        // Replace with the actual API endpoint you would be hitting
        const response = await fetch(urls.baseUrl + urls.checkForNewChatMessages);
        const data = await response.json();
        setHasNewMessages(data.new_messages);
    };

    useEffect(() => {
        // Check for messages when the component mounts
        if (user) {
            checkForNewMessages();

            // Set up an interval to check for messages regularly
            const interval = setInterval(() => {
                checkForNewMessages();
            }, 300000); // Check every 5 minutes
            // Clean up the interval on component unmount
            return () => clearInterval(interval);
        }
    }, [user]);

    return (
        <div className="messages-indicator">
            <PersonCircle size={35} style={{ color: "white" }} />
            {hasNewMessages && <span className="new-messages-badge"></span>}
        </div>
    );
};

export default MessagesIndicator;