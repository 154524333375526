import React, { useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { GrayBackgroundBox, TextBoxStyle } from './CustomStyles';
import './CustomStyles.css';
import LoadingIndicatorButton from './LoadingIndicatorButton';
import { useUser } from '../contexts/UserContext.tsx';
import SlateRichTextEditor from './SlateRichTextEditor';
import { countSlateObjectWords } from './utils';

function EditTextComponent({ beginningText, handleUpdateStoryPart }) {
    const { user } = useUser();
    const [slateContent, setSlateContent] = useState(null);
    const [slateWordCount, setSlateWordCount] = useState(0);

    const handleSlateContentChange = (newContent) => {
        setSlateContent(newContent);
        setSlateWordCount(countSlateObjectWords(newContent));
    };

    return (
        <div>
            <Form method="post" onSubmit={(event) => handleUpdateStoryPart(event, slateContent)}>
                <Form.Group>
                    <div className='display-6 mb-2 themed-text'>Your text</div>
                    <SlateRichTextEditor onSlateContentChange={handleSlateContentChange} initialValueProp={beginningText} />
                    <Form.Text className="text-muted">
                        Estimated words: {slateWordCount}
                    </Form.Text>
                    <div className="d-flex justify-content-center my-2">
                        <Button className='mx-2' variant="primary" type="submit"><LoadingIndicatorButton />Save</Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default EditTextComponent;