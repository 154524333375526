import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, FormControl, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import { EnvelopeFill, EnvelopeOpenFill, ReplyFill, Trash } from 'react-bootstrap-icons';
import { trackPromise } from 'react-promise-tracker';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { GrayBackgroundBox } from './ressources/CustomStyles.js';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton.js';
import { urls } from './ressources/urls.js';
import { postJson, removeItemFromList, shortenDate } from './ressources/utils.js';
import { useAuthenticatedUserContext } from '../App.js';

interface Message {
    id: number;
    sender_id: number;
    sender_name: string;
    receiver_name: string;
    content: string;
    creation_date: string;
}

function ChatMessagePage() {
    const location = useLocation();
    const { user } = useAuthenticatedUserContext();
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [activeMessage, setActiveMessage] = useState<Message | null>(null);
    const [recipient, setRecipient] = useState(location.state ? location.state.receiverName : "");
    const [messageContent, setMessageContent] = useState('');
    const [replyContent, setReplyContent] = useState("");
    const [messages, setMessages] = useState<Array<Message>>(() => {
        const savedMessages = window.sessionStorage.getItem('messages');
        return savedMessages ? JSON.parse(savedMessages) : [];
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonDisabledLoad, setButtonDisabledLoad] = useState(false);
    const resultsPerPage = 10;


    async function fetchChatMessages(replace: boolean, loadMore: boolean) {
        setButtonDisabledLoad(true);
        const response = await fetch(urls.baseUrl + urls.checkForNewChatMessages);
        const result = await response.json();
        let page_temp = currentPage;
        if (result.new_messages || messages.length === 0 || loadMore === true) {
            if (result.new_messages) {
                replace = true;
            }
            if (loadMore && !result.new_messages) {
                setCurrentPage(currentPage + 1);
                page_temp = page_temp + 1;
            }
            if (replace === true) {
                page_temp = 1;
                setCurrentPage(1)
            }

            await trackPromise(postJson(urls.getChatMessages, { current_page: page_temp, results_per_page: resultsPerPage }))
                .then((data) => {
                    if (data.chat_messages.length > 0) {
                        if (replace === true) {
                            setMessages(data.chat_messages)
                        } else {
                            setMessages(prevStories => [...prevStories, ...data.chat_messages]);
                        }
                        setButtonDisabledLoad(false);
                    }
                });

        }
    };

    useEffect(() => {
        window.sessionStorage.setItem('messages', JSON.stringify(messages));
    }, [messages]);

    const handleDelete = (messageId: number) => {
        postJson(urls.deleteChatMessage, { id: messageId });
        removeItemFromList(setMessages, messages, messageId);
    };

    function handleReply(message: Message) {
        setActiveMessage(message);
        setShowReplyModal(true);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        if (recipient === "" || messageContent.length === 0 || messageContent.length > 2000) {
            return;
        }
        setButtonDisabled(true);
        await postJson(urls.createChatMessage, { sender_id: user.id, receiver_name: recipient, content: messageContent }).then(data => {
            setShowAlert(true);
            if (data.error) {
                setAlertMessage(data.error)
            } else {
                setAlertMessage("Message sent.")
                setMessageContent("");
                setRecipient("");
            }
        }
        )
        setButtonDisabled(false);
    }

    async function handleSubmitReply() {
        await postJson(urls.createChatMessage, { sender_id: user.id, receiver_id: activeMessage?.sender_id, content: replyContent })
        setReplyContent("");
        setShowReplyModal(false);
    };

    async function loadMoreMessages() {
        await trackPromise(fetchChatMessages(false, true));
    };

    useEffect(() => {
        fetchChatMessages(true, false);
        setButtonDisabledLoad(false);
    }, []);

    return (
        <div className='ChatMessagePage'>
            <Container fluid className='mt-3'>
                <Row>
                    <Col xs={12} lg={5} className='mb-2'>
                        <div className={GrayBackgroundBox}>
                            <h3 className='mb-2'>Send PM <EnvelopeOpenFill /></h3>
                            <Form onSubmit={handleSubmit}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>To:</InputGroup.Text>
                                    <FormControl
                                        placeholder="Recipient's username"
                                        aria-label="Recipient's username"
                                        value={recipient}
                                        onChange={(e) => setRecipient(e.target.value)}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <FormControl
                                        style={{ minHeight: "200px" }}
                                        as="textarea"
                                        placeholder="Write your message here..."
                                        value={messageContent}
                                        onChange={(e) => setMessageContent(e.target.value)}
                                    />
                                </InputGroup>
                                <Form.Text className='text-muted'>{messageContent.length}/2000 max. characters</Form.Text>
                                <div className="d-grid gap-2 mb-2">
                                    <Button disabled={buttonDisabled} variant="primary" type="submit" className="mt-3">
                                        Send Message
                                    </Button>
                                </div>
                                {showAlert &&
                                    <div>
                                        <Alert onClose={() => setShowAlert(false)} dismissible>
                                            {alertMessage}
                                        </Alert>
                                    </div>
                                }
                            </Form>
                        </div>
                    </Col>
                    <Col xs={12} lg={7}>
                        <div className={GrayBackgroundBox}>
                            <h3>Inbox <EnvelopeFill /></h3>
                            {messages.length > 0 &&
                                <>
                                    <ListGroup className='mt-1' style={{ maxHeight: "900px", overflowY: "scroll" }}>
                                        {messages.map((message, index) => (
                                            <ListGroup.Item key={message.id} className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div >From: <b>{message.sender_name}</b></div>
                                                    <small>{shortenDate(dayjs(message.creation_date))}</small>
                                                    <div className='mt-2'>{message.content}</div>
                                                </div>
                                                <Button variant="outline-danger" onClick={() => handleDelete(message.id)}>
                                                    <Trash />
                                                </Button>
                                                <Button variant="outline-primary" className="ms-2" onClick={() => handleReply(message)}>
                                                    <ReplyFill />
                                                </Button>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    <div className="d-flex justify-content-center mt-2">
                                        {messages.length > 0 && <Button size='lg' disabled={buttonDisabledLoad} onClick={() => loadMoreMessages()}><LoadingIndicatorButton />Load older</Button>}
                                    </div>
                                </>
                            }
                        </div>
                        <Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Reply to Message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Original Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            readOnly
                                            value={activeMessage?.content || ''}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Your Reply</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={replyContent}
                                            onChange={(e) => setReplyContent(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSubmitReply}>
                                    Send Reply
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default ChatMessagePage;
