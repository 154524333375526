const presettingDescriptions = [
    {
        tag: "Short short story (single branch)",
        description: "Short writing and voting period.</br>Only a few sentences per proposal and no branching.</br>Perfect for some fast paced writing, done in half a day.",
        short_description: "This is a quick short story about [YOUR TOPIC]. ...",
        tags: [{ value: "Short story", label: "Short story" }, { value: "YOUR TOPIC", label: "YOUR TOPIC" }, { value: "YOUR GENRE", label: "YOUR GENRE" }],
        voting_period: 30,
        writing_period: 30,
        n_cycles: 10,
        n_tokens_min: 50,
        n_tokens_max: 350,
        max_proposals: 5,
        max_writers: 5,
        max_branches: 1,
        only_voted_voters: false
    },
    {
        tag: "Short story",
        description: "Medium writing and approproate voting period.</br>Only a few sentences per proposal and two branches.</br>OPens the opportunity for e.g. differnet endings.",
        short_description: "This is a short story about [YOUR TOPIC]. Try to leave a choice open at some point to branch the story.",
        tags: [{ value: "Short story", label: "Short story" }, { value: "YOUR TOPIC", label: "YOUR TOPIC" }, { value: "YOUR GENRE", label: "YOUR GENRE" }],
        voting_period: 440,
        writing_period: 1000,
        n_cycles: 10,
        n_tokens_min: 50,
        n_tokens_max: 350,
        max_proposals: 20,
        max_writers: 20,
        max_branches: 2,
        only_voted_voters: false
    },
    {
        tag: "Epic (multiple branches)",
        description: "Very long writing period, appropriate voting period.</br>Designed to write whole chapters at once.</br>Does allow up to four branches to diverse the story.</br>Write an epic story with the community.",
        short_description: "This is an epic [YOUR GENRE] story. ...",
        tags: [{ value: "Epic", label: "Epic" }, { value: "YOUR GENRE", label: "YOUR GENRE" }],
        voting_period: 10080,
        writing_period: 43200,
        n_cycles: 20,
        n_tokens_min: 3000,
        n_tokens_max: 25000,
        max_proposals: 4,
        max_writers: 3,
        max_branches: 4,
        only_voted_voters: true
    },
    {
        tag: "Poem",
        description: "Very short writing and voting period.</br>Designed to write a poem line by line in an hour.",
        short_description: "Write a poem about [YOUR TOPIC] with me line by line. ...",
        tags: [{ value: "Poem", label: "Poem" }, { value: "YOUR TOPIC", label: "YOUR TOPIC" }],
        voting_period: 5,
        writing_period: 5,
        n_cycles: 6,
        n_tokens_min: 1,
        n_tokens_max: 20,
        max_proposals: 6,
        max_writers: 999,
        max_branches: 10,
        only_voted_voters: false
    },
    {
        tag: "LLM imitation",
        description: "Write one word at a time.</br>What is the most likely outcome?",
        tags: [{ value: "LLM imitation", label: "LLM imitation" }],
        short_description: "Let's imitate a LLM.",
        voting_period: 3,
        writing_period: 2,
        n_cycles: 1000,
        n_tokens_min: 1,
        n_tokens_max: 1,
        max_proposals: 50,
        max_writers: 9999,
        max_branches: 1,
        only_voted_voters: false
    },

]

export default presettingDescriptions;