import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useParams } from 'react-router-dom';
import { useUser } from './contexts/UserContext.tsx';
import ReCAPTCHA from "react-google-recaptcha";
import { GrayBackgroundBox } from './ressources/CustomStyles.js';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton.js';
import { urls } from './ressources/urls.js';
import { getBackgroundImageStyle } from './ressources/utils.js';
import PasswordStrengthBar from 'react-password-strength-bar';

function ResetPasswordPage() {
    const location = useLocation();
    const [accessedFromProfile] = useState(location.state ? location.state.accessedFromProfile : false);
    const { user, setUser } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const recaptchaRef = React.createRef();
    const { token } = useParams();  // Extract token from URL

    useEffect(() => {

    }, []);

    const sendResetLink = async () => {
        if (email === '') {
            return;
        }
        if (!recaptchaRef.current) {
            setAlertMessage('Please solve the reCAPTCHA!');
            return false;
        }
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setAlertMessage('Please solve the reCAPTCHA!')
            return false;
        }
        setButtonDisabled(true);
        try {
            recaptchaRef.current.reset();
            const response = await trackPromise(fetch(urls.baseUrl + urls.resetPassword, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email, captcha_token: recaptchaValue })
            }));
            const data = await response.json();
            setAlertMessage(data.message);
            setEmail('');
        } catch (err) {
            console.error(err);
        }
        setButtonDisabled(false);
    };

    const updatePassword = async () => {
        setButtonDisabled(true);
        let requestUrl = '';
        if (accessedFromProfile) {
            requestUrl = urls.baseUrl + urls.changePassword;
        } else {
            requestUrl = urls.baseUrl + urls.resetPassword + `/${token}`;
        }
        try {
            const response = await trackPromise(fetch(requestUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password, old_password: oldPassword })
            }));
            const data = await response.json();
            setAlertMessage(data.message);
        } catch (err) {
            console.error(err);
        }
        setButtonDisabled(false);
        setPassword('');
        setOldPassword('');
    };

    const formMail = (
        <Form.Group className='mt-3' controlId="formBasicEmail">
            <Form.Control
                type="mail"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
        </Form.Group>
    )

    const formOldPassword = (
        <Form.Group className='mt-3' controlId="formBasicOldPassword">
            <Form.Control
                type="password"
                placeholder="Enter old password"
                maxLength={100}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
            />
        </Form.Group>
    )
    const formPassword = (
        <>
            <Form.Group className='mt-3' controlId="formBasicPassword">
                <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    maxLength={100}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </Form.Group>
            <PasswordStrengthBar minLength={8} password={password} />
        </>
    )

    return (
        <div className='ResetPasswordPage' style={getBackgroundImageStyle("login")}>
            <Container fluid className='pt-3'>
                <Row className="justify-content-md-center ">
                    <Col xs={12} md={6} xl={4} xxl={3}>
                        <div className={GrayBackgroundBox}>
                            <div className='display-6 mb-2'>
                                {token && "Reset password"}
                                {accessedFromProfile && "Update password"}
                                {(!accessedFromProfile && !token) && "Send reset password link"}
                            </div>
                            {alertMessage && <Alert variant="warning">{alertMessage}</Alert>}
                            <Form>
                                {(!token && !user) &&
                                    formMail
                                }
                                {accessedFromProfile &&
                                    formOldPassword
                                }
                                {(token || accessedFromProfile) &&
                                    formPassword
                                }
                            </Form>
                            {(!token && !accessedFromProfile) &&
                                <div className='mt-3 d-flex justify-content-center'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Lfg0okpAAAAAF4Bg9XzYfvV-o-hjH6MZCRP-PBu"
                                        onChange={() => setAlertMessage('')}
                                    />
                                </div>
                            }
                            <div className="d-flex justify-content-center my-2">
                                <Button type="submit" disabled={buttonDisabled} onClick={() => { token || accessedFromProfile ? updatePassword() : sendResetLink() }}><LoadingIndicatorButton />{token && "Update password"}{accessedFromProfile && "Update password"}{(!token && !accessedFromProfile) && "Send Reset Link"}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >

    );
}

export default ResetPasswordPage

