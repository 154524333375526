import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Row } from "react-bootstrap";
import { Google } from "react-bootstrap-icons";
import { urls } from "./urls";
import { useNavigate } from "react-router-dom";
import { OfficialGoogleButton } from "./MicroComponents.tsx";

async function getUserInfo(codeResponse) {
    var response = await fetch(urls.baseUrl + urls.googleLoginUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: codeResponse.code }),
    });
    return await response.json();
}

export default function Auth({ setUser }) {
    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
            var loginDetails = await getUserInfo(codeResponse);
            setUser(loginDetails);
            navigate("/");
        },
    });


    return (
        <>
            {(
                <div className="d-flex justify-content-center">
                    <OfficialGoogleButton onClick={googleLogin} />
                </div>
            )}
        </>
    );
}