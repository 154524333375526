import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Eye, EyeFill, EyeSlash } from "react-bootstrap-icons"
import { useUser } from './contexts/UserContext.tsx';
import { GrayBackgroundBox } from './ressources/CustomStyles.js';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton.js';
import { urls } from './ressources/urls.js';
import { getBackgroundImageStyle } from './ressources/utils.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Auth from './ressources/GoogleAuth.js';
import logo2 from '../images/icon.svg';

function LoginPage() {
    const location = useLocation();
    let from = location.state?.from?.pathname || "/";
    const { user, setUser } = useUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [eyeToggle, setEyeToggle] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    async function login(e) {
        e.preventDefault();

        const response = await trackPromise(fetch(urls.baseUrl + urls.loginUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        }));

        const userData = await response.json();

        if (userData.error) {
            setError(userData.error);
        } else {
            setUser(userData);
            navigate(from, { replace: true, state: { story: location.state?.from?.state?.story } })
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, []);

    return (
        <div className="LoginPage" style={getBackgroundImageStyle("login")}>
            <Container fluid className='pt-3 '>
                <Row className="justify-content-md-center ">
                    <Col xs={12} md={6} xl={4} xxl={3}>
                        <div className={GrayBackgroundBox}>
                            <div className="app-logo d-flex justify-content-center align-items-center" style={{ fontSize: "3rem" }}>
                                <img
                                    src={logo2}
                                    height="80"
                                    className="d-inline-block align-top"
                                    alt="Cornuvotia logo"
                                />
                                <div className='mt-3'>
                                    <span>Cornu</span>
                                    <span style={{ color: "#ffa600" }}>votia</span>
                                </div>
                            </div>
                            <div className='display-6 mb-2 d-flex justify-content-center'>Sign in</div>
                            <div className='mt-3'>
                                <GoogleOAuthProvider clientId="886787602006-83qsc5suf010nthh9qs4cp2u05m56mqh.apps.googleusercontent.com">
                                    <Auth setUser={setUser}></Auth>
                                </GoogleOAuthProvider>
                            </div>
                            <div className='d-flex justify-content-center mt-2'>
                                <Col xs={5}><hr /></Col><Col xs={2} className='text-center'>OR</Col><Col xs={5}><hr /></Col>
                            </div>
                            <Form onSubmit={login}>
                                {error && <Alert variant="danger" style={{ whiteSpace: "pre-line" }}>{error}</Alert>}
                                <Form.Group className='mt-2' controlId="formBasicEmail">
                                    <Form.Label>E-mail or username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter email or username"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className='mt-2' controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Row className='d-flex align-items-center'>
                                        <Col xs={12}>
                                            <div style={{ position: 'relative' }}>
                                                <Form.Control
                                                    type={eyeToggle ? "text" : "password"}
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                <span style={{ cursor: "pointer", position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                                    onClick={() => setEyeToggle(!eyeToggle)}>
                                                    {eyeToggle ? <EyeSlash size={"1.75rem"} /> : <Eye size={"1.75rem"} />}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Form.Group>
                                <div className="d-flex justify-content-center my-2">
                                    <Button className='mt-2' variant="success" type="submit">
                                        <LoadingIndicatorButton />
                                        Login
                                    </Button>
                                </div>
                                <Link to={"/reset_password"}>Forgot password?</Link>
                                <div className='mt-2'>
                                    <Link to="/register"><Button className='mx-1' variant="outline-primary">Sign up</Button></Link>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default LoginPage;