import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Image, InputGroup, Modal, Row, ToggleButton } from 'react-bootstrap';
import { ArrowDownCircle, Eye, EyeSlash, Files, Fullscreen, FullscreenExit } from 'react-bootstrap-icons';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext.tsx';
import AchievementBadgesComponent from './AchievementBadgesComponent';
import { TextBoxStyle } from './CustomStyles';
import './CustomStyles.css';
import { ImageProvider } from './ImageProvider';
import SlateReadOnly from './SlateReadOnly';
import { VerticallyCenteredModal } from './VerticallyCenteredModal';
import { createOffenseReport, levelToIcon, shortenDate, slateObjectToText } from './utils';
import { Tooltip } from 'react-tooltip';

function StoryAuthorsCard({ storyParts, story, userIsCreator, preselectedPartId = null }) {
    const { user } = useUser();
    let storedPosition = 0;
    const memoryPostion = parseInt(window.localStorage.getItem(`StoryPositionMemory${story.id}_${story.branch_id}`))
    if (Number.isInteger(memoryPostion)) {
        storedPosition = memoryPostion;
    }
    if (storedPosition > storyParts.length - 1) {
        storedPosition = 0;
    }
    const [rememberPositionChecked, setRememberPositionChecked] = useState(Number.isInteger(memoryPostion));
    const [activeItem, setActiveItem] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [reportModalShow, setReportModalShow] = useState(false);
    const [showEmptyParts, setShowEmptyParts] = useState(false);
    const [activeViewIndex, setActiveViewIndex] = useState(storedPosition);
    const [paginationMode, setPaginationMode] = useState(preselectedPartId === null ? false : true);
    const [page, setPage] = useState(storedPosition);
    const [firstPartOfBranchId, setFirstPartOfBranchId] = useState(0);
    const [partsToRender, setPartsToRender] = useState(storyParts);


    const refs = storyParts ? storyParts.map(() => React.createRef()) : [];
    const nonEmptyParts = storyParts ? storyParts.filter((part) => { return !slateObjectToText(part.content).startsWith("NO PROPOSALS FOR PART") }) : [];
    // const partsToRender = showEmptyParts ? storyParts : nonEmptyParts;

    const cardTextRef = useRef(null);
    const level_icon_info = levelToIcon(activeItem?.creator_info.level)


    function getOffsetTop(element) {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    function setStoredPosition(index) {
        if (rememberPositionChecked) {
            window.localStorage.setItem(`StoryPositionMemory${story.id}_${story.branch_id}`, index);
        }
    }

    const scrollToItem = (index) => {
        if (index >= 0 && index < storyParts.length) {
            // Get the cumulative top position of the item you want to scroll to
            const topPos = getOffsetTop(refs[index].current) - getOffsetTop(cardTextRef.current);

            // Scroll the container div to the item's position
            cardTextRef.current.scrollTop = topPos;
            setActiveViewIndex(index);
            if (rememberPositionChecked) {
                setStoredPosition(index);
            }
        }
    };

    useEffect(() => {
        setPartsToRender(showEmptyParts ? storyParts : nonEmptyParts);
    }, [showEmptyParts, storyParts]);

    useEffect(() => {
        if (paginationMode) {
            if (preselectedPartId && storyParts.length > 0) {
                setPage(storyParts.findIndex((part) => part.id === preselectedPartId));
            }
            else if (Number.isInteger(memoryPostion)) {
                setPage(storedPosition);
            } else {
                if (storyParts.length > 0) {
                    setPage(storyParts.length - 1);
                } else {
                    setPage(0);
                }
            }
        }
        for (var i = 0; i < storyParts.length; i++) {
            if (storyParts[i].branch_id === story.branch_id) {
                setFirstPartOfBranchId(storyParts[i].id);
                break;
            }
        }
        if (paginationMode === false) {
            scrollToItem(storedPosition);
        } else {
            setActiveItem(storyParts[page]);
            setActiveIndex(page);
        }
    }, [storyParts, paginationMode]);

    const handleScroll = () => {
        let visibleIndex = null;
        const containerTop = cardTextRef.current.scrollTop;
        const containerBottom = containerTop + cardTextRef.current.clientHeight;

        refs.some((ref, index) => {
            const itemTop = ref.current.offsetTop;
            const itemBottom = itemTop + ref.current.clientHeight;

            // Check if the majority of the item is visible
            if (itemTop < containerBottom && itemBottom > containerTop) {
                visibleIndex = index;
                return true;
            }
            return false;
        });

        if (visibleIndex !== null && visibleIndex !== activeViewIndex) {
            setActiveViewIndex(visibleIndex);
            if (rememberPositionChecked) {
                setStoredPosition(visibleIndex);
            }
        }
    };

    const handleRememberPosition = (checked) => {
        setRememberPositionChecked(checked);
        if (checked) {
            setStoredPosition(activeViewIndex)
        } else {
            window.localStorage.removeItem(`StoryPositionMemory${story.id}_${story.branch_id}`)
        }
    }

    function renderStoryPart(index, item) {
        let span = (
            <span key={item.id}>
                {item.needs_replacement ?
                    "<SORRY, THIS PART WAS DELETED BECAUSE IT VIOLATED THE GUIDELINES. THE STORY CREATOR NEEDS TO REPLACE IT>"
                    : <SlateReadOnly initialValue={item.content} />}
            </span>
        )
        let render = (
            <div key={item.id} ref={refs[index]} onClick={() => { setActiveItem(item); setActiveIndex(index) }} className='mb-2 me-1 bg-white rounded p-3' style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                {span}
            </div>
        );

        if (item.id === firstPartOfBranchId) {
            render =
                <div key={item.id} ref={refs[index]} onClick={() => { setActiveItem(item); setActiveIndex(index) }} className='mb-2 me-1 bg-white rounded p-3' style={{ borderTop: '7px solid #F7BB07', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                    {span}
                </div>
        }
        return render;
    }


    const SAC = (
        <div style={{ maxWidth: "900px" }}>
            <div className="d-flex align-items-center justify-content-between">
                <div className='mb-2 display-6 themed-text'>
                    The story
                </div>
                <div >
                    <InputGroup>
                        <div className='themed-text d-flex align-items-end' style={{ cursor: 'pointer' }}>
                            {paginationMode ?
                                <ArrowDownCircle size={22} className='me-2 mb-1' onClick={() => setPaginationMode(false)}
                                    data-tooltip-id="pagination-tooltip"
                                    data-tooltip-html="Change reading mode to: <b>Flow</b>"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left"
                                />
                                :
                                <Files size={22} className='me-2 mb-1' onClick={() => setPaginationMode(true)}
                                    data-tooltip-id="pagination-tooltip"
                                    data-tooltip-html="Change reading mode to: <b>Pagination</b>"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left"
                                />
                            }
                            {isFullscreen ?
                                <FullscreenExit size={22} className='me-2 mb-1' onClick={() => setIsFullscreen(false)}
                                    data-tooltip-id="fullscreen-tooltip"
                                    data-tooltip-html="Exit <b>fullscreen</b>fullscreen"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left" />
                                :
                                <Fullscreen size={22} className='me-2 mb-1' onClick={() => setIsFullscreen(true)}
                                    data-tooltip-id="fullscreen-tooltip"
                                    data-tooltip-html="Enter <b>fullscreen</b>"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left" />
                            }
                            {showEmptyParts ?
                                <EyeSlash size={22} className='me-2 mb-1' onClick={() => { setShowEmptyParts(false); setPage(0); }}
                                    data-tooltip-id="empty-tooltip"
                                    data-tooltip-html="<b>Hide</b> empty parts"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left" />
                                :
                                <Eye size={22} className='me-2 mb-1' onClick={() => setShowEmptyParts(true)}
                                    data-tooltip-id="empty-tooltip"
                                    data-tooltip-html="<b>Show</b> empty parts"
                                    data-tooltip-variant="success"
                                    data-tooltip-place="left" />
                            }
                            <Tooltip id="pagination-tooltip" />
                            <Tooltip id="fullscreen-tooltip" />
                            <Tooltip id="empty-tooltip" />

                        </div>
                        <InputGroup.Text style={{ background: '#6074A1', color: 'white' }}><b>{paginationMode ? page + 1 : activeViewIndex + 1}/{storyParts.length}</b></InputGroup.Text>
                        <ToggleButton
                            id="remember-check"
                            type="checkbox"
                            variant="outline-primary"
                            className='ms-1'
                            checked={rememberPositionChecked}
                            value="0"
                            onChange={(e) => { handleRememberPosition(e.currentTarget.checked); }}
                        >
                            Remember
                        </ToggleButton>
                    </InputGroup>
                </div>
            </div>
            <div >
                {paginationMode ?
                    <div style={{ ...TextBoxStyle, height: 'auto', maxHeight: "75vh", fontSize: user.font_size }}>
                        {partsToRender.length > 0 && renderStoryPart(page, partsToRender[page])}
                    </div>
                    :
                    <div
                        style={{ ...TextBoxStyle, height: 'auto', maxHeight: "75vh", fontSize: user.font_size }} onScroll={handleScroll} ref={cardTextRef}
                    >
                        {
                            (() => {
                                return (showEmptyParts && partsToRender.length > 0) ?
                                    partsToRender.map((item, index) => {
                                        return renderStoryPart(index, item);
                                    })
                                    :
                                    nonEmptyParts.map((item, index) => {
                                        return renderStoryPart(index, item);
                                    })
                            })()
                        }
                    </div>
                }
            </div>
            <div>
                {paginationMode &&
                    <Row >
                        <Col className="d-flex justify-content-center mt-2">
                            <PaginationControl
                                page={page + 1}
                                between={3}
                                total={partsToRender.length}
                                limit={1}
                                changePage={(page) => {
                                    setPage(page - 1);
                                    setActiveItem(partsToRender[page - 1]);
                                    setActiveIndex(page - 1);
                                    setStoredPosition(page - 1);
                                }}
                                ellipsis={1}
                                last={true}
                            />
                        </Col>
                    </Row>
                }
                <div className={"author-card mb-2" + (paginationMode ? "" : " mt-2")} style={{ minHeight: "100px" }}>
                    {activeItem ?
                        <Row>
                            {(activeItem?.needs_replacement && userIsCreator) ?
                                <Col className='d-flex justify-content-end'>
                                    <div>
                                        <Link to="/replace_part" state={{ storyParts: storyParts, index: activeIndex, story: story }} ><Button size='sm' variant="success">Replace part</Button></Link>
                                    </div>
                                </Col>
                                :
                                <Row>
                                    <Col>
                                        {activeItem &&
                                            <Row>
                                                <Col>
                                                    <Link to={"/profile/" + activeItem.creator_info.name}><Image src={ImageProvider.avatars[activeItem.creator_info.avatar]} thumbnail alt="Test" width='48' className='me-2 mb-2' /></Link>
                                                    <span className='me-1' style={{ fontWeight: 350, lineHeight: 1.2, fontSize: "1.5rem" }}>{activeItem.creator_info.name}</span>
                                                    {[...Array(level_icon_info[1])].map((e, i) => <Image key={i} src={level_icon_info[0]} width='24' alt="Test" />)}
                                                    <AchievementBadgesComponent userAchievements={activeItem.creator_info.achievements} />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                    <Col>
                                        <div className='mb-2'>Part created at:  <Badge bg='secondary'>{shortenDate(activeItem.creation_date)}</Badge></div>
                                        <Button size='sm' variant="outline-danger" onClick={() => setReportModalShow(true)}>Report part</Button>
                                        <VerticallyCenteredModal
                                            show={reportModalShow}
                                            onHide={() => setReportModalShow(false)}
                                            onConfirm={(text) => { createOffenseReport(activeItem.id, "part", text).then(data => { setReportModalShow(false) }); }}
                                            hastextinput="true"
                                            title="Create a report"
                                            body="Give your reasons for the report. It will be reviewed asap."
                                        />
                                    </Col>
                                </Row>
                            }
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Image src={ImageProvider.avatars["questionmark.svg"]} thumbnail width='48' className='me-2 mb-2' />
                                <span className='me-1' style={{ fontWeight: 350, lineHeight: 1.2, fontSize: "1.5rem" }}>Writer</span>
                                <Image src={ImageProvider.icons["feather_grey.svg"]} width='24' alt="Test" />
                                <div><Badge bg='secondary'>Click story part</Badge></div>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        </div >
    );

    if (isFullscreen) {
        return (
            <Modal show={isFullscreen} fullscreen={true} onHide={() => setIsFullscreen(false)} dialogClassName="modal-100w">
                <Modal.Body className='themed-component'>
                    <Row>
                        <Col xs={12} lg={3}></Col>
                        <Col xs={12} lg={6}>{SAC}</Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    } else {
        return (<div>{SAC}</div>);
    }
}


export default StoryAuthorsCard;