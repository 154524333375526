import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

function NotFoundPage() {

    return (
        <div className='NotFoundPage themed-text mt-3'>
            <Container style={{ fontFamily: 'monospace' }}>
                <Row>
                    <Col>
                        <h1>Oops!</h1>
                        <p>The page you're looking for doesn't seem to exist.</p>
                        <blockquote>And if you are supposed to be on the road, there is some chance that you will be looked for on the road and not off it.</blockquote>
                        <footer className="blockquote-footer">
                            <cite title="Source Title">Frodo</cite>
                        </footer>
                        <Button variant="primary" href="/">Return Home</Button>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default NotFoundPage;

