import React, { createContext, useContext, Dispatch, SetStateAction, ReactNode } from 'react';
import User from '../types/types.UserType.ts';

type UserContextType = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
};

const defaultContextValue: UserContextType = {
  user: null, // Assuming `null` is your initial state for no user.
  setUser: () => { }, // A no-op function for initial setup.
};
// Create a context with a default value that matches the shape of UserContextType
const UserContext = createContext<UserContextType>(defaultContextValue);

export const UserProvider = ({ children, user, setUser }: { children: ReactNode, user: User | null, setUser: Dispatch<SetStateAction<User | null>> }) => {

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);