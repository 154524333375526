import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Badge, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Activity, Bezier2, Bookmark, BookmarkFill, Gear, PauseFill, People, PersonAdd, PlayFill, Trash, Wrench } from 'react-bootstrap-icons';
import { trackPromise } from 'react-promise-tracker';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuthenticatedUserContext } from '../App.js';
import EditTextComponent from './ressources/EditTextComponent.js';
import PendingRequestSpinner from './ressources/PendingRequestSpinner.js';
import StoryAuthorsCard from './ressources/StoryAuthorsCard.js';
import StoryCard from './ressources/StoryCard.js';
import StoryTreeAccordion from './ressources/StoryTree.js';
import { VerticallyCenteredModal } from './ressources/VerticallyCenteredModal.js';
import { urls } from './ressources/urls.js';
import { convertMinutesToTime, createOffenseReport, postJson, shortenDate, trimSlateContentInPlace } from './ressources/utils.js';


function StoryPage() {
    const { paramStoryId, paramBranchId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuthenticatedUserContext();
    const location = useLocation();
    const [story, setStory] = useState(location.state ? location.state.story : null);
    const [preselectedPartId] = useState(location.state ? location.state.preselected_part_id : null);
    const [storyParts, setStoryParts] = useState<Array<any>>([]);
    const [history, setHistory] = useState([]);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [reportModalShow, setReportModalShow] = useState(false);
    const [suspendModalShow, setSuspendModalShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [error, setError] = useState(null);
    const [alertMessageCollaborator, setAlertMessageCollaborator] = useState<string | null>(null);
    const [showAlert, setShowAlert] = useState(false);
    const [suspensionWip, setSuspensionWip] = useState(false);
    const [userHasPrivilege, setUserHasPrivilige] = useState(true);
    const [showPendingRequestSpinner, setShowPendingRequestSpinner] = useState(true);
    const [collaborators, setCollaborators] = useState<Array<any>>([]);
    const [newCollaboratorName, setNewCollaboratorName] = useState('');

    const handleRemoveCollaborator = (userId: number, userName: string) => {
        postJson(urls.removeCollaborator, { user_id: userId, story_id: story.id }).then(data => {
            if (data.code === 200) {
                setCollaborators(collaborators.filter(collab => collab.user_id !== userId));
                setAlertMessageCollaborator(`Successfully removed ${userName} from collaborators`)
            }
            if (data.error) {
                setAlertMessageCollaborator(data.error);
            }
            setShowAlert(true);
        })
    };

    const handleSuspendStory = () => {
        setSuspensionWip(true);
        postJson(urls.suspendStory, { story_id: story.id }).then(data => {
            if (data.code === 200) {
                setStory({ ...story, suspension_date: true });
                setAlertMessageCollaborator(`Successfully suspended the story`)
            }
            if (data.error) {
                setAlertMessageCollaborator(data.error);
            }
            setShowAlert(true);
            setSuspensionWip(false);
        })
    };

    const handleLiftSuspension = () => {
        setSuspensionWip(true);
        postJson(urls.liftStorySuspension, { story_id: story.id }).then(data => {
            if (data.code === 200) {
                setStory({ ...story, suspension_date: null });
            }
            if (data.error) {
                setAlertMessageCollaborator(data.error);
            }
            setSuspensionWip(false);
            setStory(null);
        })

    };

    const handleAddCollaborator = (event) => {
        event.preventDefault();
        if (!newCollaboratorName) {
            return;
        }
        postJson(urls.addCollaborator, { user_name: newCollaboratorName, story_id: story.id }).then(data => {
            if (data.user_id) {
                const newCollaborator = {
                    id: data.id,
                    story_id: story.id,
                    user_id: data.user_id,
                    user_name: newCollaboratorName
                };
                setCollaborators([...collaborators, newCollaborator]);
                setNewCollaboratorName('');
                setAlertMessageCollaborator(`Successfully added ${newCollaboratorName} as collaborator`)
            }
            if (data.error) {
                setAlertMessageCollaborator(data.error);
            }
            setShowAlert(true);
        });
    };


    const contentByCreator = storyParts.reduce((acc, item) => {
        if (!acc[item.creator]) {
            acc[item.creator] = { name: item.creator_info.name, words: 0, parts: 0 };
        }
        acc[item.creator].words += item.n_tokens;
        acc[item.creator].parts += 1;
        return acc;
    }, {});

    const sortedContentByCreator = Object.values<any>(contentByCreator).sort((a, b) => b.parts - a.parts || b.words - a.words);

    const handleStoryDelete = async () => {
        trackPromise(postJson(urls.deleteNewStory, { story_id: story.id, branch_id: story.branch_id }))
            .then(data => { navigate("/profile"); if (data.error) { } });
    }

    const handleUpdateStoryPart = async (event, slateContent) => {
        event.preventDefault();

        trackPromise(postJson(urls.updatePart, { part_id: storyParts[0].id, branch_id: storyParts[0].branch_id, story_id: story.id, text: trimSlateContentInPlace(slateContent) }))
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    window.scrollTo(0, 0);
                } else {
                    storyParts[0].content = slateContent;
                    setEditShow(false);
                }
            });
    }

    const handleBookmarkClick = async () => {
        setIsBookmarked(true);
        postJson(urls.createBookmark, { branch_id: story.branch_id });
    }

    const handleBookmarkRemoveClick = async () => {
        setIsBookmarked(false);
        postJson(urls.deleteBookmark, { branch_id: story.branch_id });
    }

    useEffect(() => {
        const fetchStoryData = async () => {
            const { id, branch_id, is_data_complete } = story || {};
            const storyId = id || paramStoryId;
            const branchId = branch_id || paramBranchId;

            if (!storyId || !branchId) return;

            const fetchParams = { story_id: storyId, branch_id: branchId };
            await postJson(urls.getPrivateStoryData, fetchParams).then(data => {
                if (data.error) {
                    setUserHasPrivilige(false);
                    setShowPendingRequestSpinner(false);
                    return;
                }
                if (data.not_found) {
                    navigate("path_error");
                    return;
                }
                if (data.collaborators) {
                    setUserHasPrivilige(true);
                    setCollaborators(data.collaborators);
                }

                if (story === null || !is_data_complete) {
                    postJson(urls.getStoryBranch, fetchParams).then(data => {
                        setStory(data.story);
                    });
                }
                else {
                    if (storyParts.length === 0) {
                        postJson(urls.storyAsOneUrl, fetchParams).then(data => {
                            setStoryParts(data.parts)
                        });
                        postJson(urls.getStoryDetails, fetchParams).then(data => {
                            setIsBookmarked(data.is_bookmarked);
                            setHistory(data.history);
                        })
                    }
                }
                setShowPendingRequestSpinner(false);
            });
        };
        fetchStoryData();
    }, [story, paramStoryId, paramBranchId]);

    useEffect(() => {
        const fetchBackendData = async () => {
            if (userHasPrivilege) {
                fetch(urls.baseUrl + urls.incrementView + `/${story.id}/${story.branch_id}`);
            }
        }
        const timer = setTimeout(fetchBackendData, 60000);
        return () => clearTimeout(timer);
    }, [story]);

    return (
        <div className='StoryPage'>
            <Container fluid className='pt-3'>
                {(story?.is_data_complete && userHasPrivilege) &&
                    <div>
                        {story.is_dead &&
                            <Alert variant='danger' dismissible>
                                <Alert.Heading>Dead branch</Alert.Heading>
                                This branch has not been active for a long time and hence died out. You can no longer contribute to it.
                            </Alert>
                        }
                        <Row className='d-flex'>
                            <Col xs={12} xl={3} className='mb-5'>
                                {story.is_data_complete &&
                                    <div>
                                        <StoryCard story={story} maxWidth='450px' />
                                        <Card className="m-2 shadow-sm" style={{ maxWidth: '450px' }}>
                                            <ListGroup>
                                                <Accordion>
                                                    <Accordion.Item eventKey='0'>
                                                        <Accordion.Header>Advanced parameters</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Badge bg="light" text="dark" className="me-2">Creation date: {shortenDate(story.creation_date)}</Badge>
                                                            <Badge bg="light" text="dark" className="me-2">Creation date (with downtimes): {shortenDate(story.creation_date_downtime)}</Badge>
                                                            <Badge bg="light" text="dark" className="me-2">Writing period: {convertMinutesToTime(story.writing_period)}</Badge>
                                                            <Badge bg="light" text="dark" className="me-2">Voting period: {convertMinutesToTime(story.voting_period)}</Badge>
                                                            <Badge bg="light" text="dark" className="me-2">Max.branches: {story.max_branches}</Badge>
                                                            <Badge bg="light" text="dark" className="me-2">Split at percent: {story.split_at_percent}%</Badge>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <ListGroup.Item>
                                                    <span >
                                                        {isBookmarked ?
                                                            <Button onClick={() => handleBookmarkRemoveClick()}><BookmarkFill className='me-1' />Remove Bookmark</Button>
                                                            :
                                                            <Button onClick={() => handleBookmarkClick()}><Bookmark className='me-1' />Bookmark</Button>
                                                        }
                                                    </span>
                                                    <span className='mt-2 ms-2'>
                                                        <Button variant="outline-danger" onClick={() => setReportModalShow(true)}>Report story</Button>
                                                        <VerticallyCenteredModal
                                                            show={reportModalShow}
                                                            onHide={() => setReportModalShow(false)}
                                                            onConfirm={(text) => { createOffenseReport(story.id, "story", text).then(data => { setReportModalShow(false) }); }}
                                                            hastextinput="true"
                                                            title="Create a report"
                                                            body="Give your reasons for the report. It will be reviewed asap."
                                                        />
                                                    </span>
                                                    <div className='mt-2'>
                                                        {story.is_private &&
                                                            <>
                                                                <People /> Collaborators
                                                                <ListGroup>
                                                                    {collaborators.map(collab => (
                                                                        <ListGroupItem key={collab.id} className="d-flex justify-content-between align-items-center">
                                                                            {collab.user_name}
                                                                            {user?.id === story.creator &&
                                                                                <Trash
                                                                                    color="red"
                                                                                    onClick={() => handleRemoveCollaborator(collab.user_id, collab.user_name)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            }
                                                                        </ListGroupItem>
                                                                    ))}
                                                                </ListGroup>
                                                            </>
                                                        }
                                                        {(user?.id === story.creator && story.is_private) &&
                                                            <div>
                                                                <hr />
                                                                <Gear /> Private settings
                                                                <Form onSubmit={handleAddCollaborator} className="d-flex align-items-center my-2">
                                                                    <Form.Group className="flex-grow-1 me-2">
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={newCollaboratorName}
                                                                            onChange={(e) => setNewCollaboratorName(e.target.value)}
                                                                            placeholder="Enter new user name"
                                                                        />
                                                                    </Form.Group>
                                                                    <PersonAdd
                                                                        color='green'
                                                                        size={25}
                                                                        onClick={handleAddCollaborator}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </Form>
                                                                {showAlert &&
                                                                    <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>{alertMessageCollaborator}</Alert>
                                                                }
                                                                {!story.suspension_date &&
                                                                    <div className='mt-2 '>
                                                                        <Button variant="secondary" disabled={suspensionWip} onClick={() => setSuspendModalShow(true)}><PauseFill className='me-1' />Suspend story</Button>
                                                                        <Modal show={suspendModalShow} onHide={() => setSuspendModalShow(false)} centered size='md'>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title >
                                                                                    Confirm story suspension
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                To suspend a story means to pause it for an indefinite time. The story cycles are put on hold and no one can write or vote. You can resume the story later.<br />It allows you to go on vacation or take a break.<br />Are you sure?
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="secondary" disabled={suspensionWip} onClick={() => handleSuspendStory()}>{suspensionWip ? <Spinner size='sm' className='me-1' /> : <PauseFill className='me-1' />}Suspend story</Button>
                                                                                <Button variant='secondary' onClick={() => setSuspendModalShow(false)}>Close</Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {user?.id === story.creator &&
                                                            <>
                                                                <hr />
                                                                <Wrench /> Story management
                                                                {(storyParts.length === 1 && story.currently_writing) &&
                                                                    <div>
                                                                        <div className='mt-2'>
                                                                            {editShow ?
                                                                                <Button variant="outline-primary" onClick={() => setEditShow(false)}>Show Story</Button>
                                                                                :
                                                                                <Button variant="outline-primary" onClick={() => setEditShow(true)}>Edit Beginning</Button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(storyParts.length === 1 || story.suspension_date !== null) &&
                                                                    <div className='mt-2'>
                                                                        <span>
                                                                            <Button variant="danger" onClick={() => setModalShow(true)}><Trash className='me-1' />Delete Story</Button>
                                                                            <VerticallyCenteredModal
                                                                                show={modalShow}
                                                                                onHide={() => setModalShow(false)}
                                                                                onConfirm={() => handleStoryDelete()}
                                                                                title="Permanent deletion"
                                                                                body="Are you sure you want to delete your story permanently?"
                                                                            />
                                                                        </span>
                                                                        {story.suspension_date !== null &&
                                                                            <span className='ms-2'>
                                                                                <Button variant="secondary" disabled={suspensionWip} onClick={() => handleLiftSuspension()}>{suspensionWip ? <Spinner size='sm' /> : <PlayFill />} Lift suspension</Button>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                    </div>
                                }
                            </Col>
                            <Col xs={12} xl={6} className='mb-5'>
                                {editShow ?
                                    <div>
                                        {error &&
                                            <Alert variant="danger">{error}</Alert>
                                        }
                                        <EditTextComponent beginningText={storyParts[0].content} handleUpdateStoryPart={handleUpdateStoryPart} />
                                    </div>
                                    :
                                    <StoryAuthorsCard storyParts={storyParts} story={story} userIsCreator={user?.id === story.creator} preselectedPartId={preselectedPartId} />
                                }
                            </Col>
                            <Col xs={12} xl={3} className='mb-5'>
                                <div>
                                    <div className='display-6 mb-1 themed-text'>Contributions</div>
                                    <Table striped bordered hover size='sm' variant='light'>
                                        <thead>
                                            <tr>
                                                <th>Author</th>
                                                <th>Parts</th>
                                                <th>Total Words</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(sortedContentByCreator).map(([creator, stats]) => (
                                                <tr key={creator}>
                                                    <td><Link to={"/profile/" + stats.name}>{stats.name}</Link></td>
                                                    <td>{stats.parts}</td>
                                                    <td>{stats.words}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className='mb-5'>
                                <div className='display-6 mb-1 themed-text'>Activity <Activity /></div>
                                <ResponsiveContainer height={300}>
                                    <LineChart
                                        data={history}
                                        margin={{
                                            top: 5,
                                            right: 5,
                                            left: 5,
                                            bottom: 15,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="last_cycle" label={{ value: "Cycle", dy: 15 }} />
                                        <YAxis />
                                        <Tooltip />
                                        <Line strokeWidth="5" type="monotone" label="Votes" dataKey="n_votes" stroke="#8884d8" activeDot={{ r: 5 }} />
                                        <Line strokeWidth="5" type="monotone" dataKey="n_proposals" stroke="#82ca9d" activeDot={{ r: 5 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <Row>
                            </Row>
                            <div className='mb-5 themed-text'>
                                <div className='display-6 mb-1'>Story Tree <Bezier2 /></div>
                                <Badge bg='warning'> </Badge> = This story branch
                                <StoryTreeAccordion story={story} />
                            </div>
                        </Row>

                    </div>
                }
                {
                    showPendingRequestSpinner &&
                    <PendingRequestSpinner />
                }
                {
                    (!userHasPrivilege && !showPendingRequestSpinner) &&
                    <Container style={{ fontFamily: 'monospace' }}>
                        <Row>
                            <Col>
                                <h1>This is a private story!</h1>
                                <p>The author needs to grant you direct access.</p>
                                <blockquote>Sorry, I didn't get the memo that I wasn't supposed to be here. I'll just go ahead and file it under 'I' for 'Irrelevant'.</blockquote>
                                <footer className="blockquote-footer">
                                    <cite title="Source Title">Tony Stark</cite>
                                </footer>
                                <Button variant="primary" href="/">Return Home</Button>
                            </Col>
                        </Row>
                    </Container>
                }
            </Container >
        </ div >

    );
}

export default StoryPage;

