import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo2 from './images/icon.svg';

const CookieConsent = ({ onAccept, onReject, initialShow = false }) => {
    const [show, setShow] = useState(initialShow);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [preferences, setPreferences] = useState({
        googleAnalytics: false,
    });

    useEffect(() => {
        const consent = localStorage.getItem('cookiePreferences')
        if (!consent) {
            setShow(true);
        } else {
            const savedPreferences = JSON.parse(consent);
            setPreferences(savedPreferences);
        }
    }, []);

    const handleAcceptAll = () => {
        setPreferences({
            googleAnalytics: true,
        });
        saveConsent({
            googleAnalytics: true,
        });
    };

    const handleRejectAll = () => {
        setPreferences({
            googleAnalytics: false,
        });
        saveConsent({
            googleAnalytics: false,
        });
        if (onReject) {
            onReject();
        }
    };

    const handleSavePreferences = () => {
        saveConsent(preferences);
    };

    const saveConsent = (preferences) => {
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
        setShow(false);
        if (preferences.googleAnalytics) {
            onAccept();
        }
        // Add here any logic for other preferences if necessary
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [name]: checked,
        }));
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
            back
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="display-6 fw-bold d-flex justify-content-center">
                        <img
                            src={logo2}
                            height="80"
                            className="d-inline-block align-top"
                            alt="Cornuvotia logo"
                        />
                        <div className='mt-3'>
                            <span>Cornu</span>
                            <span style={{ color: "#ffa600" }}>votia</span>
                        </div>
                    </div>
                    <div className='mt-2'>Cookie Preferences</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ fontSize: "13px" }}>
                    <p>By using this site, you agree to our use of cookies to enhance your browsing experience, analyze site traffic, and for personalized content. You can learn more or manage your preferences at any time by visiting the <Link to="/about" state={{ openedItem: "privacypolicy" }}>Privacy Policy</Link>.
                        <br />Accept to continue or decline to limit data collection.
                    </p>
                </div>
                {showAdvancedOptions &&
                    <>
                        <b>You can customize your cookie preferences here.</b>
                        <Form>
                            <Form.Check
                                type="checkbox"
                                label="Strictly Nescessary Cookies"
                                name="necessaryCookies"
                                checked={true}
                                disabled
                            />
                            <Form.Check
                                type="checkbox"
                                label="Google Analytics"
                                name="googleAnalytics"
                                checked={preferences.googleAnalytics}
                                onChange={handleChange}
                            />
                        </Form>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleRejectAll}>Reject All</Button>
                {showAdvancedOptions ?
                    <Button variant="light" onClick={handleSavePreferences}>Save Preferences</Button>
                    :
                    <Button variant="light" onClick={() => setShowAdvancedOptions(true)}>Manage Cookies</Button>
                }

                <Button variant="primary" onClick={handleAcceptAll}>Accept All</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CookieConsent;