import { Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Offcanvas, Placeholder, Row, Accordion, Container } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { trackPromise } from 'react-promise-tracker';
import * as yup from "yup";
import { useUser } from './contexts/UserContext.tsx';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton';
import StoryCard from './ressources/StoryCard';
import { languages } from './ressources/languages';
import { urls } from './ressources/urls';
import { postJson, toLocalISOString } from './ressources/utils';
import Story from './types/types.Story';
import { FormikSelect, LanguageWithFlag } from './ressources/MicroComponents.tsx';
import dayjs from 'dayjs';


const schema = yup.object().shape({
    title: yup.string(),
    creation_date: yup.date(),
    end_date: yup.date(),
    language: yup.string(),
    voting_period_min: yup.number().positive().integer().max(999999),
    voting_period_max: yup.number().positive().integer().max(999999, `Must be less than or equal to ${999999}`),
    writing_period_min: yup.number().positive().integer().max(999999),
    writing_period_max: yup.number().positive().integer().max(999999, `Must be less than or equal to ${999999}`),
    n_tokens_min: yup.number().positive().integer().max(999999),
    n_tokens_max: yup.number().positive().integer().moreThan(yup.ref('n_tokens_min')).max(25000, `Must be less than or equal to ${25000}`),
    tags: yup.string().matches(/^([\wäöüAÖÜ\s]+(,[\wäöüAÖÜ\s]+)*)?$/, "Tags musst follow a specific format"),
});

const languageOptions = Object.keys(languages).map(language =>
    Object({ value: language, label: <LanguageWithFlag language={language} /> })
);

const SearchPage = () => {
    const { user } = useUser();
    const [currentValues, setCurrentValues] = useState<Object | null>(null);
    const [stories, setStories] = useState<Array<Story>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(true);
    const resultsPerPage = 4;

    const end_date = toLocalISOString(dayjs().add(2, 'year'));
    const creation_date = toLocalISOString(dayjs().subtract(2, 'year'));

    const [formikValues, setFormikValues] = useState(
        {
            title: '',
            creation_date: creation_date,
            end_date: end_date,
            language: (user?.languages.length || 0) > 0 ? user?.languages[0] : 'Any',
            n_tokens_min: '',
            n_tokens_max: '',
            voting_period_min: '',
            voting_period_max: '',
            writing_period_min: '',
            writing_period_max: '',
            only_voted_voters: 'either',
            exclude_user_contributions: 'true',
            writers_can_always_propose: 'either',
            writing_spots_open: 'true',
            is_in_first_writing_cycle: 'either',
            nsfw: 'false',
            tags: '',
            current_state: 'writing',
            sort_option: 'creation_date',
            sorting_direction: 'asc',
        }
    )

    function handleLoadMoreClick() {
        if (currentValues !== null && currentPage !== 1) {
            fetchStories(currentValues, false, currentPage);
        }
    }


    async function fetchStories(values: any, replace = true, page = currentPage) {
        setButtonDisabled(true);
        setFormikValues(values);
        const fetchValues = { ...values };
        fetchValues["creation_date"] = dayjs(fetchValues["creation_date"]).toISOString()
        fetchValues["end_date"] = dayjs(fetchValues["end_date"]).toISOString()
        setShowSearchBar(false);
        const search_page = replace ? 1 : page;
        await trackPromise(postJson(urls.storySearch, { search_values: fetchValues, results_per_page: resultsPerPage, current_page: search_page }))
            .then((data) => {
                if (replace) {
                    setStories(data.stories);
                    setCurrentValues(values);
                    setCurrentPage(1);
                } else {
                    setStories(prevStories => [...prevStories, ...data.stories]);
                }
                setCurrentPage(prevPage => prevPage + 1);
            });
        setButtonDisabled(false);
    };

    useEffect(() => {
        if (currentValues !== null && currentPage !== 1) {
            fetchStories(currentValues, false);
        }
    }, []);



    return (
        <div className="SearchPage">
            <div className='d-flex justify-content-center mt-3'>
                <Button type="button" size='lg' variant='search' className="d-flex align-items-center" onClick={() => setShowSearchBar(true)}>
                    <Search className='me-1' />Show search bar
                </Button>
            </div>
            <Offcanvas show={showSearchBar} onHide={() => setShowSearchBar(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Search bar</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Formik
                        initialValues={formikValues}
                        validationSchema={schema}
                        onSubmit={async (values, { setSubmitting }) => {
                            // send the values to backend
                            fetchStories(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ values,
                            isSubmitting, setFieldValue, handleSubmit, handleChange, errors, touched }) => (
                            <div>
                                <FormikForm noValidate onSubmit={handleSubmit}>
                                    <div >
                                        <Row>
                                            <Form.Group as={Col} controlId="formLanguage" className='form-group-margin'>
                                                <Form.Label><b>Language</b></Form.Label>
                                                <FormikSelect
                                                    name="language"
                                                    options={languageOptions}
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    defaultValue={user?.languages.map(language => Object({ value: language, label: language }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formSubmit" className='mt-4'>
                                                <Button type="submit" variant='search' size='lg' disabled={isSubmitting} className="d-flex align-items-center">
                                                    <LoadingIndicatorButton /><Search className='me-1' />Search
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                        <Row className='form-group-margin'>
                                            <Form.Group as={Col}>
                                                <Form.Label><b>Created after</b></Form.Label>
                                                <Form.Control type="datetime-local" name="creation_date" value={values.creation_date} onChange={(event) => { handleChange(event); }} isValid={touched.creation_date && !errors.creation_date} isInvalid={!!errors.creation_date} />
                                            </Form.Group>
                                        </Row>
                                        <Row className='form-group-margin'>
                                            <Form.Group as={Col}>
                                                <Form.Label><b>Ends before</b></Form.Label>
                                                <Form.Control type="datetime-local" name="end_date" value={values.end_date} onChange={(event) => { handleChange(event); }} isValid={touched.end_date && !errors.end_date} isInvalid={!!errors.end_date} />
                                            </Form.Group>
                                        </Row>
                                        <Row className='form-group-margin'>
                                            <Form.Group as={Col} controlId="formNTokensMin">
                                                <Form.Label><b>{"Min. tokens >="}</b></Form.Label>
                                                <Form.Control type="number" placeholder="Enter minimum number of tokens" name="n_tokens_min" value={values.n_tokens_min} onChange={handleChange} isValid={touched.n_tokens_min && !errors.n_tokens_min} isInvalid={!!errors.n_tokens_min} />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formNTokensMax">
                                                <Form.Label><b>{"Max. tokens <="}</b></Form.Label>
                                                <Form.Control type="number" placeholder="Enter maximum number of tokens" name="n_tokens_max" value={values.n_tokens_max} onChange={handleChange} isValid={touched.n_tokens_max && !errors.n_tokens_max} isInvalid={!!errors.n_tokens_max} />
                                            </Form.Group>
                                        </Row>
                                        <Row className='form-group-margin'>
                                            <Form.Group as={Col} controlId="formWritingCycleMin">
                                                <Form.Label><b>{"Writing period >="}</b></Form.Label>
                                                <Form.Control type="number" placeholder="Enter minutes" name="writing_period_min" value={values.writing_period_min} onChange={handleChange} isValid={touched.writing_period_min && !errors.writing_period_min} isInvalid={!!errors.writing_period_min} />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formWritingCycleMax">
                                                <Form.Label><b>{"Writing period <="}</b></Form.Label>
                                                <Form.Control type="number" placeholder="Enter minutes" name="writing_period_max" value={values.writing_period_max} onChange={handleChange} isValid={touched.writing_period_max && !errors.writing_period_max} isInvalid={!!errors.writing_period_max} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} controlId="formSort" className='form-group-margin'>
                                                <Form.Control as="select" name="sort_option" value={values.sort_option} onChange={handleChange}>
                                                    <option value="creation_date">Sort by Creation Date</option>
                                                    <option value="end_date">Sort by End Date</option>
                                                    <option value="writing_period">Sort by Writing Period</option>
                                                    <option value="voting_period">Sort by Voting Period</option>
                                                    <option value="min_tokens">Sort by Minimum Tokens</option>
                                                    <option value="max_tokens">Sort by Maximum Tokens</option>
                                                    <option value="n_writers">Sort by Writers</option>
                                                    <option value="n_parts">Sort by Parts</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Form.Group as={Col} controlId="formSortingDirection">
                                                <Field name="sorting_direction" as={Form.Check} type="radio" id="ascending" label="Ascending" value="asc" />
                                                <Field name="sorting_direction" as={Form.Check} type="radio" id="descending" label="Descending" value="desc" />
                                            </Form.Group>
                                        </Row>
                                        <Accordion defaultActiveKey={'-1'}>
                                            <Accordion.Item eventKey='0'>
                                                <Accordion.Header >Advanced search</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='form-group-margin'>
                                                        <Form.Group as={Col} controlId="formVotingCycleMin">
                                                            <Form.Label><b>{"Voting period <="}</b></Form.Label>
                                                            <Form.Control type="number" placeholder="Enter minutes" name="voting_period_min" value={values.voting_period_min} onChange={handleChange} isValid={touched.voting_period_min && !errors.voting_period_min} isInvalid={!!errors.voting_period_min} />
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formVotingCycleMax">
                                                            <Form.Label><b>{"Voting period >="}</b></Form.Label>
                                                            <Form.Control type="number" placeholder="Enter minutes" name="voting_period_max" value={values.voting_period_max} onChange={handleChange} isValid={touched.voting_period_max && !errors.voting_period_max} isInvalid={!!errors.voting_period_max} />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row >
                                                        <Form.Group as={Col} controlId="formExcludeStoriesWithUserContribution" className='search-radio-box'>
                                                            <Form.Label><b>Exclude stories with user contributions</b></Form.Label>
                                                            <Field name="exclude_user_contributions" as={Form.Check} type="radio" id="exclude_user_contributions_true" label="True" value="true" />
                                                            <Field name="exclude_user_contributions" as={Form.Check} type="radio" id="exclude_user_contributions_false" label="False" value="false" />
                                                            <Field name="exclude_user_contributions" as={Form.Check} type="radio" id="exclude_user_contributions_either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formOnlyVotedVoters" className='search-radio-box'>
                                                            <Form.Label><b>Only voted voters</b></Form.Label>
                                                            <Field name="only_voted_voters" as={Form.Check} type="radio" id="only_voted_voters_true" label="True" value="true" />
                                                            <Field name="only_voted_voters" as={Form.Check} type="radio" id="only_voted_voters_false" label="False" value="false" />
                                                            <Field name="only_voted_voters" as={Form.Check} type="radio" id="only_voted_voters_either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formWritersCanAlwaysPropose" className='search-radio-box'>
                                                            <Form.Label><b>Writers can always propose</b></Form.Label>
                                                            <Field name="writers_can_always_propose" as={Form.Check} type="radio" id="writers_can_always_propose_true" label="True" value="true" />
                                                            <Field name="writers_can_always_propose" as={Form.Check} type="radio" id="writers_can_always_propose_false" label="False" value="false" />
                                                            <Field name="writers_can_always_propose" as={Form.Check} type="radio" id="writers_can_always_propose_either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formIsInFirstWritingCycle" className='search-radio-box'>
                                                            <Form.Label><b>First writing cycle</b></Form.Label>
                                                            <Field name="is_in_first_writing_cycle" as={Form.Check} type="radio" id="is_in_first_writing_cycle_either" label="True" value="true" />
                                                            <Field name="is_in_first_writing_cycle" as={Form.Check} type="radio" id="is_in_first_writing_cycle_false" label="False" value="false" />
                                                            <Field name="is_in_first_writing_cycle" as={Form.Check} type="radio" id="is_in_first_writing_cycle_either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formCurrentState" className='search-radio-box'>
                                                            <Form.Label><b>Current state</b></Form.Label>
                                                            <Field name="current_state" as={Form.Check} type="radio" id="currently-writing" label="Writing" value="writing" />
                                                            <Field name="current_state" as={Form.Check} type="radio" id="currently-voting" label="Voting" value="voting" />
                                                            <Field name="current_state" as={Form.Check} type="radio" id="currently-either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formWritingSpotsOpen" className='search-radio-box'>
                                                            <Form.Label><b>Open writing spots</b></Form.Label>
                                                            <Field name="writing_spots_open" as={Form.Check} type="radio" id="writing_spots_open_true" label="True" value="true" />
                                                            <Field name="writing_spots_open" as={Form.Check} type="radio" id="writing_spots_open_false" label="False" value="false" />
                                                            <Field name="writing_spots_open" as={Form.Check} type="radio" id="writing_spots_open_either" label="Either" value="either" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formNsfw" className='search-radio-box'>
                                                            <Form.Label style={{ color: 'red' }}><b>NSFW</b></Form.Label>
                                                            <Field name="nsfw" as={Form.Check} type="radio" id="nsfw_true" label="True" value="true" />
                                                            <Field name="nsfw" as={Form.Check} type="radio" id="nsfw_false" label="False" value="false" />
                                                            <Field name="nsfw" as={Form.Check} type="radio" id="nsfw_either" label="Either" value="either" />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row >
                                                        <Form.Group as={Col} controlId="formTags" className='form-group-margin'>
                                                            <Form.Label><b>Tags</b></Form.Label>
                                                            <Form.Control type="text" maxLength={200} placeholder="Enter tags in the following format: tag1, tag2, tag3..." name="tags" value={values.tags} onChange={handleChange} isValid={touched.tags && !errors.tags} isInvalid={!!errors.tags} />
                                                            <Form.Text className="text-muted">
                                                                E.g. 'fantasy, middle ages, short'
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Row>
                                                    <Form.Group className='form-group-margin'>
                                                        <Form.Label><b>Title contains</b></Form.Label>
                                                        <Field type="text" name="title" as={Form.Control} maxLength={100} />
                                                    </Form.Group>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <Button type="submit" variant='search' disabled={isSubmitting} className="d-flex align-items-center">
                                                            <LoadingIndicatorButton /><Search className='me-1' />Search
                                                        </Button>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </FormikForm>
                            </div>
                        )}
                    </Formik>
                </Offcanvas.Body>
            </Offcanvas>
            <Container fluid>
                <Row className='justify-content-md-center my-1'>
                    {buttonDisabled ?
                        [...Array(4)].map((v, index) => (
                            <Col xs={12} lg={4} xl={3} key={index}>
                                <Card className="m-2 shadow-sm" style={{ borderRadius: '15px' }}>
                                    <Card.Body>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                        :
                        stories.map(story => (
                            <Col key={story.id} className="d-flex justify-content-center" xs={12} lg={4} xl={3} >
                                <StoryCard story={story} />
                            </Col>
                        ))
                    }
                </Row>
                <div className="d-flex justify-content-center mb-5">
                    {stories.length > 0 && <Button size='lg' variant='search' type='button' disabled={buttonDisabled} onClick={() => handleLoadMoreClick()}><LoadingIndicatorButton />Load More</Button>}
                    {stories.length === 0 && currentValues !== null && <div className='display-6 themed-text'>No results</div>}
                </div>
            </Container>
        </div >
    );
};


export default SearchPage;

