import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { Button, ToggleButton } from 'react-bootstrap';
import { Type, TypeBold, TypeItalic, TypeUnderline } from 'react-bootstrap-icons';
import { createEditor } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { useUser } from '../contexts/UserContext.tsx';

let initialValue = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]

const SlateRichTextEditor = ({ onSlateContentChange, initialValueProp = null }) => {
    const { user } = useUser();
    const [editor] = useState(() => withReact(createEditor()));
    const [editorValue, setEditorValue] = useState(initialValueProp || initialValue);
    const [isActiveBold, setIsActiveBold] = useState(false);
    const [isActiveItalic, setIsActiveItalic] = useState(false);
    const [isActiveUnderline, setIsActiveUnderline] = useState(false);

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    useEffect(() => {
        if (initialValueProp) {
            setEditorValue(initialValueProp);
        }
    }, [initialValueProp]);

    useEffect(() => {
        onSlateContentChange(editorValue);
    }, [editorValue]);

    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>;
        }
        if (leaf.italic) {
            children = <em>{children}</em>;
        }
        if (leaf.underline) {
            children = <u>{children}</u>;
        }

        return <span {...attributes}>{children}</span>;
    };

    const handleSelect = useCallback(() => {
        const markButtonDict = {
            "bold": setIsActiveBold,
            "italic": setIsActiveItalic,
            "underline": setIsActiveUnderline,
        }
        const marks = editor.getMarks();
        if (marks) {
            Object.keys(markButtonDict).map(mark =>
                markButtonDict[mark](Object.keys(marks).includes(mark))
            )
        }

    }, [editor]);

    const toggleFormat = (editor, format) => {
        const isActive = isFormatActive(editor, format);
        if (isActive) {
            editor.removeMark(format);
        } else {
            editor.addMark(format, true);
        }
    };

    const isFormatActive = (editor, format) => {
        const marks = editor.getMarks();
        if (marks) {
            return marks[format] === true;
        } else {
            return false;
        }
    };

    const FormatButton = ({ format, children, isActive, setIsActive }) => {
        return (
            <ToggleButton
                className='me-2 themed-text'
                type='checkbox'
                size='sm'
                variant='outline-secondary'
                checked={isActive}
                value="1"
                onMouseDown={event => {
                    event.preventDefault();
                    setIsActive(!isActive);
                    toggleFormat(editor, format);
                }}
            >
                {children}
            </ToggleButton>
        );
    };

    return (
        <div>
            <span className='me-2 themed-text'><Type size={24} /></span>
            <FormatButton format="bold" isActive={isActiveBold} setIsActive={setIsActiveBold}><TypeBold size={24} /></FormatButton>
            <FormatButton format="italic" isActive={isActiveItalic} setIsActive={setIsActiveItalic}><TypeItalic size={24} /></FormatButton>
            <FormatButton format="underline" isActive={isActiveUnderline} setIsActive={setIsActiveUnderline}><TypeUnderline size={24} /></FormatButton>

            <Slate editor={editor} value={editorValue} initialValue={editorValue}
                onChange={value => {
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (isAstChange) {
                        onSlateContentChange(value);
                    }
                }}>
                <Editable
                    className='mt-1'
                    style={{
                        overflowY: "auto",
                        backgroundColor: "#FFFFFF",
                        minHeight: "150px",
                        maxHeight: "75vh",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", /* Subtle shadow instead of border */
                        fontSize: user.font_size,
                        padding: "10px",
                    }}
                    renderLeaf={renderLeaf}
                    onSelect={handleSelect}
                    onKeyDown={event => {
                        if (!event.ctrlKey) {
                            return;
                        }

                        switch (event.key) {
                            case 'b':
                                event.preventDefault();
                                toggleFormat(editor, 'bold');
                                break;
                            case 'i':
                                event.preventDefault();
                                toggleFormat(editor, 'italic');
                                break;
                            case 'u':
                                event.preventDefault();
                                toggleFormat(editor, 'underline');
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </Slate>
        </div>
    );
};

export default SlateRichTextEditor;