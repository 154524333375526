import { Formik } from 'formik';
import React, { useEffect, useState, useMemo } from 'react';
import { Accordion, Alert, Badge, Button, Card, Form, Col, Row, Image, ToggleButton, ButtonGroup, Modal } from 'react-bootstrap';
import { Book, Lock, Image as ImageIcon } from 'react-bootstrap-icons';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import * as yup from "yup";
import { useAuthenticatedUserContext } from '../App.js';
import './Components.css';
import presettingDescriptions from './ressources/CreateStoryFormPresettings.js';
import { ImageProvider } from './ressources/ImageProvider.js';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton.js';
import SlateRichTextEditor from './ressources/SlateRichTextEditor.js';
import { languages } from './ressources/languages.js';
import tags from './ressources/tags.js';
import { urls } from './ressources/urls.js';
import { convertMinutesToTime, countSlateObjectWords, postJson, toLocalISOString, trimSlateContentInPlace } from './ressources/utils.js';
import { FormikCreatableSelect, FormikSelect, LanguageWithFlag } from './ressources/MicroComponents.tsx';
import { Tooltip } from 'react-tooltip'
import dayjs from 'dayjs';
import CountdownTimer from './ressources/CountdownTimer.js';

const parameterNames = {
    "title": "Title",
    "creation_date": "Creation date",
    "short_description": "Short description",
    "voting_period": "Voting period",
    "writing_period": "Writing period",
    "n_cycles": "Number of cycles",
    "n_tokens_min": "Minimum number of words",
    "n_tokens_max": "Maximum number of words",
    "max_proposals": "Maximum proposals",
    "max_writers": "Maximum writers",
    "max_branches": "Maximum branches",
    "split_at_percent": "Split at percent",
    "tags": "Tags",
}


const max_date = dayjs().add(1, 'month');
const max_short_description_length = 350;
const voting_period = 2;
const writing_period = 2;
const n_cycles = 10;

const schema = yup.object().shape({
    title: yup.string().required('Required'),
    creation_date: yup.date().required('Required').min(toLocalISOString(dayjs())).max(max_date),
    language: yup.string(),
    short_description: yup.string().required('Required').max(max_short_description_length, `Should not exceed ${max_short_description_length} characters`),
    voting_period: yup.number().required('Required').positive().integer().max(999999, `Must be less than or equal to ${999999}`),
    writing_period: yup.number().required('Required').positive().integer().max(999999, `Must be less than or equal to ${999999}`),
    n_cycles: yup.number().required('Required').positive().integer().max(9999, `Must be less than or equal to ${9999}`),
    picture: yup.string(),
    n_tokens_min: yup.number().required('Required').positive().integer().max(25000, `Must be less than or equal to ${25000}`),
    // .when("language", {
    //     is: (language) => language !== "Any",
    //     then: (schema) => schema.min(6),
    //     otherwise: (schema) => schema.min(1)
    // }),
    n_tokens_max: yup.number().required('Required').positive().integer().max(25000, `Must be less than or equal to ${25000}`).min(yup.ref('n_tokens_min'), `Must be more than 'Minium number of tokens'`),
    max_proposals: yup.number().positive().integer().max(9999, `Must be less than or equal to ${9999}`),
    max_branches: yup.number().required('Required').positive().integer().max(999, `Must be less than or equal to ${999}`),
    max_writers: yup.number().positive().integer().max(9999, `Must be less than or equal to ${9999}`),
    split_at_percent: yup.number().positive().integer().max(50, `Must be less than or equal to ${50}`).nullable().transform((_, val) => val === Number(val) ? val : null),
    writers_can_always_propose: yup.bool().required('Required'),
    only_voted_voters: yup.bool().required('Required'),
    nsfw: yup.bool().required('Required'),
    is_private: yup.bool().required('Required'),
});

const languageOptions = Object.keys(languages).map(language =>
    Object({ value: language, label: <LanguageWithFlag language={language} /> })
);

const tagOptions = tags.map(tag =>
    Object({ value: tag, label: tag })
);

const CreateStoryForm = ({ setGuideSection }) => {
    const localDatetime = toLocalISOString(dayjs());
    const end_date = toLocalISOString(dayjs().add(((voting_period + writing_period) * n_cycles), 'minute'));
    const [alertState, setAlertState] = useState({ showAlert: false, variant: "success", message: "", heading: "" });
    const [alertStateIds, setAlertStateIds] = useState({ story_id: null, branch_id: null });
    const { user } = useAuthenticatedUserContext();
    const [openAccordion, setOpenAccordion] = useState('0');
    const [imageRadioValue, setImageRadioValue] = useState('pre');
    const [customImageObjectURL, setCustomImageObjectURL] = useState<null | string>(null);
    const [customImageName, setCustomImageName] = useState<null | string>(null);
    const [customImageMessage, setCustomImageMessage] = useState<string>('You can only generate a custom image once a week. It is designed from your Title and Short Description.');

    const [presettings, setPresettings] = useState({
        short_description: '',
        tags: [],
        voting_period: voting_period,
        writing_period: writing_period,
        n_cycles: n_cycles,
        n_tokens_min: 1,
        n_tokens_max: 1000,
        max_proposals: 10,
        max_writers: 10,
        max_branches: 4,
        only_voted_voters: false,
        end_date: end_date
    })
    const [endDateParameters, setEndDateParameters] = useState({ creation_date: localDatetime, voting_period: presettings.voting_period.valueOf(), writing_period: presettings.writing_period.valueOf(), n_cycles: presettings.n_cycles.valueOf() });
    const [storyCount, setStoryCount] = useState(0);
    const [generationCredits, setGenerationCredits] = useState(0);
    const [slateContent, setSlateContent] = useState(null);
    const [slateWordCount, setSlateWordCount] = useState(0);
    const [showImageModal, setShowImageModal] = useState(false);


    useEffect(() => {
        fetch(urls.baseUrl + urls.getUserRunningStoryCount).then(response => response.json()).then(data => {
            setStoryCount(data.n_running_stories);
        })
        fetch(urls.baseUrl + urls.getUserGenerationCredits).then(response => response.json()).then(data => {
            setGenerationCredits(data.credits);
            if (data.message) {
                setCustomImageMessage(data.message);
            }
        })
    }, [customImageObjectURL]);

    async function handleImageGenerateClick(title: string, short_description: string) {
        if (title === '' || short_description === '') {
            setCustomImageMessage('No Title or Short Description found')
            return;
        }
        setGenerationCredits(0);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ title: title, short_description: short_description }),
        };
        const response = await trackPromise(fetch(urls.baseUrl + urls.generateImage, requestOptions));
        const data = await response.json();
        if (data.error) {
            setCustomImageMessage(data.error);
            setCustomImageObjectURL(null);
            return;
        }
        setCustomImageObjectURL(`https://cornuvotia.net/images/${data.image_name}`);
        setCustomImageName(data.image_name);
    }

    const handleSlateContentChange = (newContent) => {
        setSlateContent(newContent);
        setSlateWordCount(countSlateObjectWords(newContent));
    };


    function handlePresettingClick(setting) {
        const { ...values } = setting;
        let localDatetime = dayjs().add(((values.voting_period + values.writing_period) * values.n_cycles), 'minute');
        values.end_date = toLocalISOString(localDatetime);
        setPresettings(values);
    }

    function handleAccordionItemClick(value) {
        if (openAccordion === value) {
            setOpenAccordion("-1");
            setGuideSection(-1);
        } else {
            setOpenAccordion(value);
            setGuideSection(parseInt(value));
        }
    }

    function handleAccordionButtonClick(value) {
        setOpenAccordion(value);
        setGuideSection(parseInt(value))
    }

    function setEndDate(name: string, newValue: any, setFieldValue: any) {
        if (newValue === 0 || (isNaN(newValue) && name !== 'creation_date') || newValue === '') {
            return;
        }
        let newEndDateParameters = {
            ...endDateParameters,
            [name]: newValue
        };

        setEndDateParameters(newEndDateParameters);

        // Recalculate the end_date using the new parameters
        if (((newEndDateParameters.voting_period + newEndDateParameters.writing_period) * newEndDateParameters.n_cycles) > 4.73 * 100000000) {
            return;
        }
        let newEndDate = dayjs(newEndDateParameters.creation_date).add(((newEndDateParameters.voting_period + newEndDateParameters.writing_period) * newEndDateParameters.n_cycles), 'minute');
        // Update the value of the 'end_date' field using setFieldValue
        setFieldValue('end_date', toLocalISOString(newEndDate));
    }

    // Function to validate the form and log errors
    const validateForm = (values) => {
        const errors = {};
        try {
            schema.validateSync(values, { abortEarly: false });
            return true;
        } catch (validationError) {
            if (validationError.errors) {
                validationError.inner.forEach((error) => {
                    errors[error.path] = error.message;
                });
            }

            let validationErrorMessage = <div></div>
            if (Object.keys(errors).length > 0) {
                validationErrorMessage = <div>
                    <div>Some of your story parameters are invalid. Please review them. Consult the Guide for more information.</div>
                    {
                        Object.keys(errors).map((error) => {
                            return <div><b>{parameterNames[error]}</b>: {errors[error]}</div>
                        })
                    }
                </div>
            }

            setAlertState({
                showAlert: true,
                variant: "warning",
                heading: "Invalid story parameters",
                message: validationErrorMessage,
            })
            return false;
        }
    };

    const initialValues = useMemo(() => ({
        title: '',
        creation_date: localDatetime,
        now: true,
        end_date: presettings.end_date,
        language: user.languages.length > 0 ? user.languages[0] : 'Any',
        short_description: presettings.short_description,
        voting_period: presettings.voting_period,
        writing_period: presettings.writing_period,
        n_cycles: presettings.n_cycles,
        picture: 'scifi.png',
        n_tokens_min: presettings.n_tokens_min,
        n_tokens_max: presettings.n_tokens_max,
        max_proposals: presettings.max_proposals,
        max_branches: presettings.max_branches,
        max_writers: presettings.max_writers,
        split_at_percent: 30,
        writers_can_always_propose: true,
        only_voted_voters: presettings.only_voted_voters,
        nsfw: false,
        is_private: false,
        tags: presettings.tags,
    }), [presettings]);


    return (
        < div >
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const finalizedValues = { ...values };
                    finalizedValues["creator"] = user.id
                    finalizedValues["story_beginning"] = trimSlateContentInPlace(slateContent);
                    finalizedValues["creation_date"] = dayjs(values["creation_date"]).toISOString()
                    finalizedValues["end_date"] = dayjs(values["end_date"]).toISOString()
                    finalizedValues["tags"] = values["tags"].map((tag) => tag.value);
                    if (customImageName) {
                        values["picture"] = customImageName;
                    }

                    const requestOptions: any = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                    };
                    try {
                        requestOptions.body = JSON.stringify({ createForm: finalizedValues });
                        const response = await trackPromise(fetch(urls.baseUrl + urls.createStoryUrl, requestOptions));
                        if (response.ok) {
                            const data = await response.json();
                            if (data.story_id) {
                                setAlertState({
                                    showAlert: true,
                                    variant: "success",
                                    heading: "Excellent!",
                                    message: "You successfully created a story.",
                                })
                                setAlertStateIds({
                                    story_id: data.story_id,
                                    branch_id: data.branch_id
                                })
                                setStoryCount(storyCount + 1);
                                setOpenAccordion('-1');
                                resetForm();
                            } else {
                                window.scrollTo(0, 0);
                                if (data.error) {
                                    setAlertState({
                                        showAlert: true,
                                        variant: "danger",
                                        heading: "Server error. Try again later.",
                                        message: data.error
                                    })
                                    return;
                                }
                                if (data.n_tokens < finalizedValues.n_tokens_min || data.n_tokens > finalizedValues.n_tokens_max) {
                                    setAlertState({
                                        showAlert: true,
                                        variant: "danger",
                                        heading: "Story length error",
                                        message: `Your story length does not match the minimum or maximum word requirement (word count: ${data.n_tokens})`
                                    })
                                    return;
                                } if (data.is_story_language === false) {
                                    setAlertState({
                                        showAlert: true,
                                        variant: "danger",
                                        heading: "Story language error",
                                        message: `Story beginning language does no match selected story language`
                                    })
                                    return;
                                } if (data.n_running_stories >= Math.floor(user.level)) {
                                    setAlertState({
                                        showAlert: true,
                                        variant: "danger",
                                        heading: "Story limit error",
                                        message: `You've reached your story limit`
                                    })
                                    return;
                                } if (data.moderation_flag !== false) {
                                    setAlertState({
                                        showAlert: true,
                                        variant: "danger",
                                        heading: "Content error",
                                        message: `${data.moderation_flag}`
                                    })
                                    return;
                                }
                            }
                        } else {
                            setAlertState({
                                showAlert: true,
                                variant: "danger",
                                heading: "Uff!",
                                message: "Something went wrong. Try again later"
                            })
                        }

                        // Perform any additional actions based on the response data
                    } catch (error) {
                        console.error('An error occurred while submitting the form:', error);
                    }
                    setSubmitting(false);
                    window.scrollTo(0, 0);
                }}
                initialValues={initialValues}
            >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting,
                }) => (
                    <Form noValidate onSubmit={(e) => {
                        e.preventDefault();
                        if (validateForm(values)) {
                            handleSubmit();
                        }
                        window.scrollTo(0, 0);
                    }}>
                        <Row className='justify-content-md-center'>
                            <Col>
                                {alertState.showAlert &&
                                    <Alert style={{ whiteSpace: 'pre-wrap' }} variant={alertState.variant} onClose={() => setAlertState(prevState => ({ ...prevState, showAlert: false }))} dismissible>
                                        <Alert.Heading>{alertState.heading}</Alert.Heading>
                                        {alertState.message}
                                        {alertStateIds.story_id &&
                                            <Link to={`/story/${alertStateIds.story_id}/${alertStateIds.branch_id}`} className='ms-2'>
                                                <Button size='sm' variant='outline-primary'>Show story</Button>
                                            </Link>
                                        }
                                    </Alert>
                                }
                                <div>
                                    <div className='d-flex align-items-center display-6 mb-2 themed-text'>
                                        Create a new community story
                                    </div>
                                    <Card>
                                        <Card.Header><b>Presettings</b></Card.Header>
                                        <Card.Body>
                                            {presettingDescriptions.map((setting) => (
                                                <>
                                                    <Badge className='me-2' style={{ cursor: 'pointer' }} bg='write' pill
                                                        data-tooltip-id="presetting-tooltip"
                                                        data-tooltip-html={setting.description}
                                                        data-tooltip-place="top"
                                                        data-tooltip-variant="success"
                                                        onClick={() => handlePresettingClick(setting)}>{setting.tag}</Badge>
                                                    <Tooltip id="presetting-tooltip" />
                                                </>
                                            ))}

                                        </Card.Body>
                                    </Card>
                                    <Accordion activeKey={openAccordion} className='my-3'>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header onClick={() => handleAccordionItemClick("0")} ><h5>Description</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group className='form-group-margin' controlId="formTitle">
                                                    <Form.Label ><b>Title</b></Form.Label>
                                                    <Form.Control type="text" maxLength={100} placeholder="Enter title" name="title" value={values.title} onChange={handleChange} isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                                                </Form.Group>

                                                <Form.Group className='form-group-margin' controlId="formShortDescription">
                                                    <Form.Label><b>Short Description</b></Form.Label>
                                                    <Form.Control as="textarea" maxLength={max_short_description_length} placeholder="Writing instructions and world building" name="short_description" value={values.short_description} onChange={handleChange} isValid={touched.short_description && !errors.short_description} isInvalid={!!errors.short_description} />
                                                    <Form.Text className="text-muted">
                                                        Max. length: {values.short_description.length}/{max_short_description_length}
                                                    </Form.Text>
                                                </Form.Group>

                                                <Row>
                                                    <Form.Group as={Col} xs={12} xl={5} className='form-group-margin' controlId="formLanguage">
                                                        <Form.Label><b>Select Language</b></Form.Label>
                                                        <FormikSelect
                                                            name="language"
                                                            options={languageOptions}
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            defaultValue={user.languages.map(language => Object({ value: language, label: language }))}
                                                        />
                                                        <Form.Text className="text-muted">
                                                            {"Writers can only submit proposals in this language (works only with min. words > 25)"}
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Form.Group as={Col} xs={7} xl={5} controlId="formPicture" className='mb-1'>
                                                        <ButtonGroup>
                                                            <ToggleButton
                                                                key={1}
                                                                id={`radio-1`}
                                                                type="radio"
                                                                value={"custom"}
                                                                checked={imageRadioValue === "custom"}
                                                                variant='outline-primary'
                                                                onChange={(e) => setImageRadioValue(e.currentTarget.value)}
                                                            >
                                                                Generated image
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                key={2}
                                                                id={`radio-2`}
                                                                type="radio"
                                                                value={"pre"}
                                                                checked={imageRadioValue === "pre"}
                                                                variant='outline-primary'
                                                                onChange={(e) => setImageRadioValue(e.currentTarget.value)}
                                                            >
                                                                Standard image
                                                            </ToggleButton>
                                                        </ButtonGroup>
                                                        {imageRadioValue === "pre" ?
                                                            <Form.Control as="select" className='mt-2' name="picture" value={values.picture} onChange={handleChange}>
                                                                {Object.keys(ImageProvider.storybanner).map((keyName, i) => (
                                                                    <option key={keyName} value={keyName}>
                                                                        {keyName.replace(".jpg", "").replace(".png", "")}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            :
                                                            <div className='mt-2' >
                                                                <span style={{ color: 'red', fontSize: '0.8rem' }}>{customImageMessage}</span>
                                                                <div className='mt-1'>
                                                                    <Button disabled={generationCredits === 0} onClick={() => handleImageGenerateClick(values.title, values.short_description)} className='d-flex align-items-center' variant='secondary' type='button'>
                                                                        <ImageIcon className='me-1' /> Generate <LoadingIndicatorButton />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Form.Group>
                                                    <Col xs={5} xl={2}>
                                                        <div style={{ height: "115px" }}>
                                                            <Image rounded height="100%" onClick={() => setShowImageModal(true)} src={imageRadioValue === "pre" ? ImageProvider.storybanner[values.picture] : customImageObjectURL}></Image>
                                                        </div>
                                                    </Col>
                                                    <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg" centered>
                                                        <Modal.Body>
                                                            <Image src={imageRadioValue === "pre" ? ImageProvider.storybanner[values.picture] : customImageObjectURL} style={{ width: '100%' }} />
                                                        </Modal.Body>
                                                    </Modal>
                                                </Row>
                                                <Row>
                                                    <Form.Group className='form-group-margin' controlId="formTags">
                                                        <Form.Label><b>Tags</b></Form.Label>
                                                        <FormikCreatableSelect
                                                            name="tags"
                                                            options={tagOptions}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row>

                                                </Row>
                                                <Row>
                                                    <Button type='button' className='mt-3' onClick={() => handleAccordionButtonClick("1")}>Next</Button>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey='1'>
                                            <Accordion.Header onClick={() => handleAccordionItemClick("1")} ><h5>Runtime</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Label><b>Creation Date</b></Form.Label>
                                                <Row>
                                                    <Col xs={3}><Form.Check type="checkbox" label="Now" name="now" checked={values.now} onChange={(event) => { handleChange(event); !values.now && setFieldValue('creation_date', toLocalISOString(dayjs())); }} /></Col>
                                                    <Form.Group as={Col} className='form-group-margin' controlId="formCreationDate">
                                                        <Form.Control disabled={values.now} type="datetime-local" name="creation_date" value={values.creation_date} onChange={(event) => { handleChange(event); setEndDate(event.currentTarget.name, event.currentTarget.value, setFieldValue) }} isValid={(touched.creation_date && !errors.creation_date)} isInvalid={!!errors.creation_date} />
                                                    </Form.Group>
                                                </Row>
                                                <Row className='form-group-margin'>
                                                    <Form.Group as={Col} controlId="formWritingPeriod">
                                                        <Form.Label><b>Writing Period</b> (minutes)</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter writing period" name="writing_period" value={values.writing_period} onChange={(event) => { handleChange(event); setEndDate(event.currentTarget.name, parseInt(event.currentTarget.value), setFieldValue) }} isValid={touched.writing_period && !errors.writing_period} isInvalid={!!errors.writing_period} />
                                                        <Form.Text className="text-muted">
                                                            {convertMinutesToTime(values.writing_period)}
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formVotingPeriod">
                                                        <Form.Label><b>Voting Period</b> (minutes)</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter voting period" name="voting_period" value={values.voting_period} onChange={(event) => { handleChange(event); setEndDate(event.currentTarget.name, parseInt(event.currentTarget.value), setFieldValue) }} isValid={touched.voting_period && !errors.voting_period} isInvalid={!!errors.voting_period} />
                                                        <Form.Text className="text-muted">
                                                            {convertMinutesToTime(values.voting_period)}
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formNCycles">
                                                        <Form.Label><b>Number of cycles</b></Form.Label>
                                                        <Form.Control type="number" placeholder="Enter cycles" name="n_cycles" value={values.n_cycles} onChange={(event) => { handleChange(event); setEndDate(event.currentTarget.name, parseInt(event.currentTarget.value), setFieldValue) }} isValid={touched.n_cycles && !errors.n_cycles} isInvalid={!!errors.n_cycles} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formEndDate">
                                                        <Form.Label><b>End date</b></Form.Label>
                                                        <Form.Control disabled={true} type="datetime-local" name="end_date" value={values.end_date} onChange={handleChange} isValid={touched.end_date && !errors.end_date} isInvalid={!!errors.end_date} />
                                                        <Form.Text className="text-muted">
                                                            Runtime: {convertMinutesToTime((values.writing_period + values.voting_period) * values.n_cycles)}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Button type='button' className='mt-2' onClick={() => handleAccordionButtonClick("2")}>Next</Button>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey='2'>
                                            <Accordion.Header onClick={() => handleAccordionItemClick("2")} ><h5>Limits</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <Row className='form-group-margin'>
                                                    <Form.Group as={Col} controlId="formNTokensMin">
                                                        <Form.Label><b>Minimum Number of Words</b></Form.Label>
                                                        <Form.Control type="number" maxLength={5} placeholder="Enter minimum number of words" name="n_tokens_min" value={values.n_tokens_min} onChange={handleChange} isValid={touched.n_tokens_min && !errors.n_tokens_min} isInvalid={!!errors.n_tokens_min} />
                                                        <Form.Text className="text-muted">
                                                            ~ Typing time: {convertMinutesToTime(values.n_tokens_min / 40 + 1)}
                                                        </Form.Text>
                                                        <Form.Text className="text-muted">
                                                            <div>~ Reading time: {convertMinutesToTime(values.n_tokens_min / 238 + 1)}</div>
                                                        </Form.Text>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formNTokensMax">
                                                        <Form.Label><b>Maximum Number of Words</b></Form.Label>
                                                        <Form.Control type="number" maxLength={5} placeholder="Enter maximum number of words" name="n_tokens_max" value={values.n_tokens_max} onChange={handleChange} isValid={touched.n_tokens_max && !errors.n_tokens_max} isInvalid={!!errors.n_tokens_max} />
                                                        <Form.Text className="text-muted">
                                                            ~ Typing time: {convertMinutesToTime(values.n_tokens_max / 40 + 1)}
                                                        </Form.Text>
                                                        <Form.Text className="text-muted">
                                                            <div>~ Reading time: {convertMinutesToTime(values.n_tokens_max / 238 + 1)}</div>
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formMaxProposals">
                                                        <Form.Label><b>Maximum Proposals</b></Form.Label>
                                                        <Form.Control type="number" maxLength={4} placeholder="Enter maximum number of proposals" name="max_proposals" value={values.max_proposals} onChange={handleChange} isValid={touched.max_proposals && !errors.max_proposals} isInvalid={!!errors.max_proposals} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formMaxWriters">
                                                        <Form.Label><b>Maximum Writers</b></Form.Label>
                                                        <Form.Control type="number" maxLength={4} placeholder="Enter maximum number of writers" name="max_writers" value={values.max_writers} onChange={handleChange} isValid={touched.max_writers && !errors.max_writers} isInvalid={!!errors.max_writers} />
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Button type='button' className='mt-3' onClick={() => handleAccordionButtonClick("3")}>Next</Button>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header onClick={() => handleAccordionItemClick("3")} ><h5>Advanced settings</h5></Accordion.Header>
                                            <Accordion.Body>
                                                <Row className='form-group-margin'>
                                                    <Form.Group as={Col} controlId="formMaxBranches">
                                                        <Form.Label><b>Maximum Branches</b></Form.Label>
                                                        <Form.Control type="number" maxLength={3} placeholder="Enter maximum number of branches" name="max_branches" value={values.max_branches} onChange={handleChange} isValid={touched.max_branches && !errors.max_branches} isInvalid={!!errors.max_branches} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formSplitAtPercent">
                                                        <Form.Label><b>Split At Percent</b></Form.Label>
                                                        <Form.Control type="number" maxLength={2} placeholder="Enter split at percent" name="split_at_percent" value={values.split_at_percent} onChange={handleChange} isValid={touched.split_at_percent && !errors.split_at_percent} isInvalid={!!errors.split_at_percent} />
                                                    </Form.Group>
                                                </Row>
                                                <Row className='form-group-margin'>
                                                    <Form.Group as={Col} controlId="formOnlyVotedVoters">
                                                        <b><Form.Check type="checkbox" label="Only Voted Voters" name="only_voted_voters" checked={values.only_voted_voters} onChange={handleChange} /></b>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formWritersCanAlwaysPropose">
                                                        <b><Form.Check type="checkbox" label="Writers Can Always Propose" name="writers_can_always_propose" checked={values.writers_can_always_propose} onChange={handleChange} /></b>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formNsfw">
                                                        <b><Form.Check type="checkbox" label="NSFW" name="nsfw" checked={values.nsfw} onChange={handleChange} style={{ color: 'red' }} /></b>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formIsPrivate">
                                                        <b><Form.Check type="checkbox" label={<>Private<Lock className='ms-1' /></>} name="is_private" checked={values.is_private} onChange={handleChange} style={{ color: 'grey' }} /></b>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Button type='button' className='mt-2' onClick={() => handleAccordionButtonClick("-1")}>Start writing</Button>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>

                                    <Row className='form-group-margin'>
                                        <div className='display-6 mb-2 themed-text'>The beginning</div>
                                        <SlateRichTextEditor onSlateContentChange={handleSlateContentChange} />
                                        {/* <Form.Control style={{ fontSize: user.font_size, height: ((values.n_tokens_max / 25) * 50) > 600 ? 600 : ((values.n_tokens_max / 25) * 50) }} as="textarea" spellCheck="true" placeholder="Begin your story here..." name="story_beginning" value={values.story_beginning} onChange={handleChange} isValid={touched.story_beginning && !errors.story_beginning} isInvalid={!!errors.story_beginning} /> */}
                                        <span className="text-muted">
                                            Estimated words: {slateWordCount} (Min. {values.n_tokens_min} Max. {values.n_tokens_max})
                                        </span>
                                    </Row>
                                    <CountdownTimer minutes={values.writing_period} />
                                    <Row>
                                        <div className="d-flex justify-content-center my-2">
                                            <Button size='lg' variant="write" type="submit" disabled={isSubmitting || Math.floor(user.level) <= storyCount}>
                                                <LoadingIndicatorButton />
                                                Create a <Book size={"28px"} className='mx-1' />  for everyone
                                            </Button>
                                        </div>
                                    </Row>
                                    <Alert variant='info' dismissible>
                                        You already created <b>{storyCount} / {Math.floor(user.level)}</b> currently running stories. Reach the next level to create even more.
                                    </Alert>
                                </div>
                            </Col>
                        </Row>
                    </Form >
                )}
            </Formik>
        </div >
    );
};


export default CreateStoryForm;