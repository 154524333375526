import { Container, Spinner } from "react-bootstrap";

function PendingRequestSpinner({ serverError = false }) {
    return (
        <Container
            style={{
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white'
            }}
        >
            {serverError ?
                <div className='display-6'>Server error. Try again later.</div>
                :
                <Spinner animation="border" variant="primary">
                </Spinner>
            }
        </Container>
    );
}

export default PendingRequestSpinner;