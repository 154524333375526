import React, { useEffect, useState } from 'react';
import { Col, Container, Tab, Tabs } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import changelog from '../../shared_ressources/CHANGELOG.md';
import roadmap from '../../shared_ressources/ROADMAP.md';

function ChangelogPage() {
    const [markdownContent, setMarkdownContent] = useState('');
    const [markdownContentRoadmap, setMarkdownContentRoadmap] = useState('');

    useEffect(() => {
        fetch(changelog) // Fetching local markdown file
            .then(response => response.text())
            .then(text => setMarkdownContent(text))
        fetch(roadmap) // Fetching local markdown file
            .then(response => response.text())
            .then(text => setMarkdownContentRoadmap(text))
    }, []);

    return (
        <div className='ChangelogPage'>
            <Container className='mt-3 themed-text'>
                <Tabs
                    defaultActiveKey="changelog"
                    justify
                >
                    <Tab eventKey="changelog" title="Changelog">
                        <ReactMarkdown className="mt-2">{markdownContent}</ReactMarkdown>
                    </Tab>
                    <Tab eventKey="roadmap" title="Roadmap">
                        <ReactMarkdown className="mt-2">{markdownContentRoadmap}</ReactMarkdown>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
}

export default ChangelogPage;