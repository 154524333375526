import React, { useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Components.css';
import CreateStoryForm from './CreateStoryForm.tsx';

function CreateStoryPage() {
    const [guideSection, setGuideSection] = useState(0);

    return (
        <div className="LandingPage">
            <Container fluid className='pt-3'>
                <Row>
                    <Col xs={12} lg={7}>
                        <CreateStoryForm setGuideSection={setGuideSection} />
                    </Col>
                    <Col xs={12} lg={4} className='mb-2'>
                        <div className='themed-text'>

                            <div className='display-6'>{guideSection !== -1 ? "Guide" : "Creator responsibilites"}</div>

                            <ul>
                                {guideSection === 0 &&
                                    <>
                                        <li><b>Presettings</b>: Use the presettings to get a sense of realistic story parameters. They fill in aspects like Runtime and Limits</li>
                                        <li><b>Short Description</b>: This acts as a concise introduction to your story. It should encapsulate the core idea or theme of your story. Tell the potential writers what you'd expect from a continuation, writing style etc.</li>
                                        <li><b>Language</b>: Determines the expected language for all proposals for the story. This is programmatically enforced and cannot be changed after setting.</li>
                                        <li><b>Tags</b>: Tags act as quick descriptors, helping potential readers identify key themes or elements of your story. Choose tags that encapsulate the most relevant and significant aspects, aiding in categorization and searchability.</li>
                                        <li><b>Picture</b>: Choose an image that resonates with the essence of your story.</li>
                                    </>
                                }
                                {guideSection === 1 &&
                                    <>
                                        <li><b>Creation Date</b>: Specifies the starting point of the first writing cycle. The date must fall within the range of the current date and up to a month from the present day, ensuring timely progression.</li>
                                        <li><b>Voting Period</b>: Dictates the duration for which voting on a story remains open. This is a window of opportunity for participants to cast their votes and make their preferences known. The length of the voting period can affect the pace and dynamics of the story's progression, so choose wisely!<div className='text-muted'>(Keep in mind the avg. reading speed is 238 words/minute)</div></li>
                                        <li><b>Writing Period</b>: Designates the timeframe allocated for participants to contribute their writings to the story. Just like the voting period, this duration impacts the story's pace, so ensure it allows sufficient time for creativity without causing delays.<div className='text-muted'>(Keep in mind the avg. typing speed is 40 words/minute)</div></li>
                                        <li><b>Number of Cycles</b>: Each cycle comprises one writing and one voting period. Setting the number of cycles essentially determines the number of chapters or parts your story will comprise. A higher count can lead to a more intricate narrative, while a lower count might produce a concise tale.</li>
                                    </>
                                }
                                {guideSection === 2 &&
                                    <>
                                        <li><b>Minimum Number of Words</b>: Establishes the least number of words that every segment of the story must contain. A low number allows a fast paced community story writing while a high number forces writers to really dig into the story.</li>
                                        <li><b>Maximum Number of Words (1-25000)</b>: Sets an upper limit to the amount of content in each story segment. A low number makes sure the story doesn't overwhelm the reader. Higher numbers allow more depth.<div className='text-muted'>(Keep in mind the calculated typing time does not account for thinking.)</div></li>
                                        <li><b>Maximum Proposals</b>: Defines the ceiling on the number of suggestions that can be made during each cycle. This ensures a manageable number of options for voters and avoids over-saturation of ideas.</li>
                                        <li><b>Maximum Writers</b>: Determines the cap on the number of unique participants allowed to contribute to the story. This setting can be useful to maintain a certain level of cohesiveness in the story's direction, avoiding too many diverging voices.</li>
                                    </>
                                }
                                {guideSection === 3 &&
                                    <>
                                        <li><b>Maximum Branches</b>: As stories evolve, they might branch out into multiple paths based on voting outcomes. This setting decides the limit on how many different paths or branches the story can have. <div className='text-muted'>(If you want to be sure there is only one story line, set this value to 1.)</div></li>
                                        <li><b>Split At Percent (1-50)</b>: Decide the vote percentage at which a story should branch. For instance, at 50%, the story will branch only if two proposals have an equal vote count. At 33%, it allows for up to three distinct branches, providing more narrative possibilities etc.. Leave empty if you want no branching narratives.</li>
                                        <li><b>Only Voted Voters</b>: This restriction ensures that only those who have previously contributed to the story get a say in its direction. Initially, this means only the creator has the voting right, but as more writers join, they too gain this privilege, preserving the story's original integrity.</li>
                                        <li><b>Writers Can Always Propose</b>: This option ensures that users who already participated in the story (e.g. have a story part) can always create a proposal whether the maximum proposal limit is reached or not. Turning this off forces writers to create their proposals early in the writing cycle.</li>
                                        <li><b>NSFW</b>: This option flags your story as 'not safe for work'. Users are warned that this story may contain explicit content before they can interact with it. The story still has to adhere to the writing guidelines.</li>
                                        <li><b>Private</b>: Tick this option to create a story where only you can invite people to write. Invitations are managed on the story page after you created it. Private stories don't contribute to achievement, rank or leaderboard progress. They can not be found via Search or Explore.</li>
                                    </>
                                }
                                {guideSection === -1 &&
                                    <>
                                        <li><b>Let's go</b>: Your first and foremost objective is to set intruiging story parameters and write an engaging beginning that others want to follow.</li>
                                        <li><b>Keep it nice and clean</b>: If you want your story to succeed you should have an eye on the content of proposals and parts. Report them if they violate the <Link to="/about">community guidlines</Link>.</li>
                                        <li><b>Replace lost content</b>: Parts can be lost when they are reported or a writer deletes his account, leaving holes in the storyline. Your job is to fix these holes to maintain a complete story.</li>
                                    </>
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CreateStoryPage;