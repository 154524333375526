import { Link } from "react-router-dom";

const IntroductionComponent = () => (
    <div>
        <h3>Welcome to Cornuvotia: A Realm of Collaborative Creativity and Competitive Storytelling</h3>
        <h5>Unleash Your Imagination, Join a Community of Storytellers</h5>

        <p>Cornuvotia emerges from the enchanting idea that the fusion and expansion of shared fantasies can create a uniquely stimulating experience. This platform is a melting pot of creative minds, where stories are not just written but are brought to life through the collective imagination of its community.</p>

        <h5>A Space for Everyone: Amateurs and Professionals Alike</h5>
        <p>Whether you're an aspiring writer looking to hone your craft, or a seasoned scribe testing your mettle in a competitive arena, Cornuvotia welcomes you. Here, every voice matters, and every word contributes to the ever-evolving tapestry of tales.</p>

        <h5>Unique Features: Blend of Collaboration and Competition</h5>
        <p>At Cornuvotia, the story is king, and its journey is as diverse as its contributors. With customizable story parameters, you can participate in crafting tales that vary from single-word contributions to full chapters. Opt for quick, rapid-fire cycles or immerse yourself in longer, more thoughtful writing rhythms. The choice is yours.</p>

        <ul>
            <li><strong>Creating a Story:</strong> Any user can initiate a story, setting unique parameters like word limits, cycle lengths, number cycles and thematic tags. All those are explained during your story creation. After creation the story immediately enters the first writing cycle, where other users and the story creator can propose a continuation.</li>
            <li><strong>Editing Capability:</strong> Contributions can be edited within the writing cycle.</li>
            <li><strong>Levels and Privileges:</strong> Users gain levels through participation and victories, unlocking the ability to create more stories.</li>
        </ul>

        <h5>Community and Culture: Where Imagination Meets Competition</h5>
        <p>The heart of Cornuvotia is its community, fostering a culture of collaborative imagination blended with the thrill of competitive writing. Our platform is a playground for the mind, where having fun is just as important as crafting compelling narratives.</p>

        <ul>
            <li><strong>Voting Process:</strong> Post-writing cycle, an anonymous voting phase determines the next story segment. Keep in mind that your vote is NOT retractable. You can see the vote distribution after you voted.</li>
            <li><strong>Voting Restrictions:</strong> Self-voting is prohibited, with options to limit voting to active story participants.</li>
            <li><strong>AND AGAIN:</strong> The first full cycle ends with the second story part and the writing cycle begins again for the third. Another chance to be a co-author or establish your reign. This process repeats for 'n' cycles, where 'n' is the number of cycles set by the creator.</li>
        </ul>

        <h5>Branching Narratives: Explore or Forge Numerous Paths</h5>
        <p>Our unique branching feature allows stories to diverge into different directions, offering a multiverse of possibilities. Follow a path that intrigues you, or explore multiple branches to experience every twist and turn of the narrative landscape.</p>

        <ul>
            <li><strong>Branching Mechanism:</strong> Stories can branch out based on vote percentages set by the creator.</li>
            <li><strong>Multiple Branches:</strong> Users can explore or contribute to various story branches.</li>
        </ul>

        <h5>Dive Right In:</h5>
        <ul>
            <li><Link to="/explore"><strong>Explore:</strong></Link> An easy way to discover random stories that are currently in their writing or voting cycle. Be inspired by browsing through them.</li>
            <li><Link to="/search"><strong>Search:</strong></Link> A more elaborate way to find exactly what you are looking for. Search for stories that adhere to parameters you enjoy.</li>
            <li><Link to="/create"><strong>Create:</strong></Link> Eager to start your own collaborative story? Go for it. Our guide will help you set everything up.</li>
        </ul>

        <p>As a new member of Cornuvotia, a world of stories awaits you. Instantly find tales to contribute to or immerse yourself in the works of others. Use the 'Explore' feature for a spontaneous adventure or utilize the search function for specific themes or formats. And when you're ready, step into the storyteller's shoes and create your first narrative.</p>

        <h4>Your Story Awaits</h4>
    </div>

)

export { IntroductionComponent };