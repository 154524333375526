import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table, Form, ListGroup, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { GrayBackgroundBox } from './ressources/CustomStyles';
import { VerticallyCenteredModal } from './ressources/VerticallyCenteredModal';
import { urls } from './ressources/urls';
import { postJson, removeItemFromList, slateObjectToText } from './ressources/utils';
import { useNavigate } from 'react-router-dom';
import SlateRichTextEditor from './ressources/SlateRichTextEditor';
import StoryCard from './ressources/StoryCard';

interface Report {
    id: number;
    user_id: number;
    detailObject: any;
    report: any;
    type: string;
}

interface UserBan {
    id: number;
    user_id: number;
}

interface Feedback {
    id: number;
}

type InsertionType = "part" | "proposal"

function AdminPage() {
    const [offenseReports, setOffenseReports] = useState<Array<Report>>([]);
    const [userBans, setUserBans] = useState<Array<UserBan>>([]);
    const [feedback, setFeedback] = useState<Array<Feedback>>([]);
    const [modalShow, setModalShow] = useState(false);
    const [reportDetails, setReportDetails] = useState<any | null>(null);
    const [currentlyFetchedStory, setCurrentlyFetchedStory] = useState<any | null>(null);
    const [deleteStoryId, setDeleteStoryId] = useState(0);
    const [deleteUserId, setDeleteUserId] = useState(0);
    const [addPartBranchId, setAddPartBranchId] = useState(0);
    const [addPartUserId, setAddPartUserId] = useState(0);
    const [deleteUserName, setDeleteUserName] = useState("");
    const [deleteStoriesChecked, setDeleteStoriesChecked] = useState(true);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [newUsers, setNewUsers] = useState<Array<any>>([]);
    const [activeMenuKey, setActiveMenuKey] = useState("Feedback");
    const [radioValue, setRadioValue] = useState<InsertionType>("part");


    const [slateContent, setSlateContent] = useState(null);
    const [initialSlateContent, setInitialSlateContent] = useState(null);

    const navigate = useNavigate();

    const handleSlateContentChange = (newContent) => {
        setSlateContent(newContent);
    };



    useEffect(() => {
        fetch(urls.baseUrl + urls.getAllOffenseReports).then(response => response.json()).then(data => {
            setOffenseReports(data.offense_reports);
        });
        fetch(urls.baseUrl + urls.getAllUserBans).then(response => response.json()).then(data => {
            setUserBans(data.user_bans);
        });
        fetch(urls.baseUrl + urls.getAllFeedback).then(response => response.json()).then(data => {
            setFeedback(data.feedback);
        });
        fetch(urls.baseUrl + urls.getNewUsers).then(response => response.json()).then(data => {
            setNewUsers(data.users);
        });
    }, []);

    const handleReportIdClick = async (report) => {
        postJson(urls.getOffenseReportData, { report_id: report.id })
            .then(data => { data.reported_data.report = report; setReportDetails(data.reported_data) });
        setModalShow(true);
    }

    const handleNewUserIdClick = async (userName) => {
        navigate("/profile/" + userName, { replace: true })
    }


    const handleReportDelete = async (report_id) => {
        postJson(urls.deleteOffenseReport, { id: report_id })
        removeItemFromList(setOffenseReports, offenseReports, report_id);
    }

    const handleUserBanDelete = async (user_id) => {
        postJson(urls.liftUserBan, { user_id: user_id })
        removeItemFromList(setUserBans, userBans, user_id);
    }

    const handleFeedbackDelete = async (feedback_id) => {
        postJson(urls.deleteFeedback, { feedback_id: feedback_id })
        removeItemFromList(setFeedback, feedback, feedback_id);
    }

    const handleFetchBranchInfo = async (branch_id) => {
        postJson(urls.getBranchInfoAdmin, { branch_id: branch_id }).then(data => {
            setCurrentlyFetchedStory(data.branch_info);
        })
    }

    const handleAddPartorProposal = async (e) => {
        e.preventDefault();
        const url = radioValue === "part" ? urls.addPartArtificially : urls.addProposalArtificially;
        var user_id = addPartUserId;
        if (Number.isNaN(user_id)) {
            user_id = 0;
        }
        console.log(user_id)
        postJson(url, { branch_id: addPartBranchId, text: slateContent, user_id: addPartUserId })
            .then(data => {
                if (data.error) {
                    setDeleteMessage(data.error);
                }
                if (data.code === 200) {
                    setDeleteMessage("Success");
                    setAddPartBranchId(0);
                    setInitialSlateContent(null);
                }
            });
    }

    const handleDeleteEntitySubmit = async (e, entity: string) => {
        e.preventDefault();
        const url = entity === "story" ? urls.deleteStory : urls.deleteUser;
        const payload = entity === "story" ? { branch_id: deleteStoryId } : { user_id: deleteUserId, user_name: deleteUserName };

        postJson(url, payload).then(data => {
            if (data["error"]) {
                setDeleteMessage(data["error"])
            } else {
                setDeleteMessage("Success")
            }
        })
        setDeleteStoryId(0);
        setDeleteUserId(0);
    }



    const handleBanCreation = async () => {
        if (reportDetails === null) {
            return;
        }
        let { user_id, ...detailObject } = reportDetails;
        let { report, ...reasonObject } = detailObject;
        postJson(urls.banUser, { report_id: report.id, user_id: user_id, reason: reasonObject })
        if (detailObject.type === "proposal") {
            postJson(urls.deleteProposalUrl, { proposal_id: report.reported_proposal_id })
        } else if (detailObject.type === "story") {
            postJson(urls.deleteStory, { branch_id: report.reported_branch_id })
        } else if (detailObject.type === "part") {
            postJson(urls.deletePart, { part_id: report.reported_part_id })
        }
        setReportDetails(null);
    }

    return (
        <div className='AdminPage'>
            <Container fluid>
                <VerticallyCenteredModal
                    show={modalShow}
                    onHide={() => { setModalShow(false); setReportDetails(null); }}
                    onConfirm={() => handleBanCreation().then(() => setModalShow(false))}
                    title="Ban user"
                    body={slateObjectToText(reportDetails?.content)}
                />
                <Row>
                    <Col xs={12} xl={2} style={{ padding: "0px" }} className='mb-3'>
                        <ListGroup as="ul" variant="flush">
                            {["Feedback", "Bans", "Delete", "Parts", "New users"].map((key) => (
                                <ListGroup.Item
                                    as="li"
                                    action
                                    active={activeMenuKey === key}
                                    onClick={() => setActiveMenuKey(key)}
                                    style={{ cursor: 'pointer' }}
                                    variant='primary'
                                >
                                    {key}
                                </ListGroup.Item>
                            ))
                            }
                        </ListGroup>
                        {currentlyFetchedStory && <StoryCard story={currentlyFetchedStory} />}
                    </Col>
                    <Col>
                        {activeMenuKey === 'Bans' &&
                            <>
                                <Row>
                                    <Col>
                                        <div className={GrayBackgroundBox}>
                                            <h3>Offense reports</h3>
                                            {offenseReports.length > 0 &&
                                                <div style={{ overflowY: "auto", maxHeight: "25rem" }}>
                                                    <Table striped bordered hover responsive size='sm'>
                                                        <thead>
                                                            <tr>
                                                                <th><Trash /></th>
                                                                {Object.keys(offenseReports[0]).reverse().map((reportKey) => (
                                                                    <th key={reportKey}>{reportKey.replace("reported_", "")}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {offenseReports.map((report, index) => (
                                                                <tr>
                                                                    <td><Button size='sm' variant='danger' onClick={() => handleReportDelete(report.id)}><Trash /></Button></td>
                                                                    {Object.keys(offenseReports[0]).reverse().map((reportKey) => {
                                                                        if (reportKey === "id") {
                                                                            return (<td key={report.id}><Button size='sm' onClick={() => handleReportIdClick(report)}>{report[reportKey]}</Button></td>)
                                                                        } else {
                                                                            return (<td key={report.id}>{report[reportKey]}</td>)
                                                                        }
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={GrayBackgroundBox}>
                                            <h3>User Bans</h3>
                                            {userBans.length > 0 &&
                                                <div style={{ overflowY: "auto", maxHeight: "25rem" }}>
                                                    <Table striped bordered hover responsive size='sm'>
                                                        <thead>
                                                            <tr>
                                                                <th><Trash /></th>
                                                                {Object.keys(userBans[0]).reverse().map((reportKey) => (
                                                                    <th key={reportKey}>{reportKey}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {userBans.map((userBan) => (
                                                                <tr>
                                                                    <td><Button size='sm' variant='danger' onClick={() => handleUserBanDelete(userBan.user_id)}><Trash /></Button></td>
                                                                    {Object.keys(userBans[0]).reverse().map((reportKey) => {
                                                                        return (<td key={userBan.id}>{userBan[reportKey]}</td>)
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        {activeMenuKey === 'Feedback' &&
                            <>
                                <Row>
                                    <Col>
                                        <div className={GrayBackgroundBox}>
                                            <h3>Feedback</h3>
                                            {feedback.length > 0 &&
                                                <div style={{ overflowY: "auto", maxHeight: "25rem" }}>
                                                    <Table striped bordered hover responsive size='sm'>
                                                        <thead>
                                                            <tr>
                                                                <th><Trash /></th>
                                                                {Object.keys(feedback[0]).reverse().map((reportKey) => (
                                                                    <th key={reportKey}>{reportKey}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {feedback.map((entity) => (
                                                                <tr>
                                                                    <td><Button size='sm' variant='danger' onClick={() => handleFeedbackDelete(entity.id)}><Trash /></Button></td>
                                                                    {Object.keys(feedback[0]).reverse().map((reportKey) => {
                                                                        return (<td key={entity.id}>{entity[reportKey]}</td>)
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        {activeMenuKey === 'Delete' &&
                            <>
                                <Row className='mt-2'>
                                    <Col>
                                        <Form onSubmit={(e) => handleDeleteEntitySubmit(e, "story")}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Delete story by branch ID</Form.Label>
                                                <Form.Control type="number" placeholder="Enter ID" value={deleteStoryId} onChange={(e) => setDeleteStoryId(parseInt(e.target.value))} />
                                            </Form.Group>
                                            <div>
                                                <Button variant="danger" type="submit">
                                                    Delete
                                                </Button>
                                                <Button className='ms-2' variant="secondary" type="button" onClick={() => handleFetchBranchInfo(deleteStoryId)}>
                                                    Fetch
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <Form onSubmit={(e) => handleDeleteEntitySubmit(e, "user")}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Delete user by ID or name</Form.Label>
                                                <Form.Control type="number" placeholder="Enter ID" value={deleteUserId} onChange={(e) => setDeleteUserId(parseInt(e.target.value))} />
                                                <Form.Control className='mt-1' type="text" placeholder="Enter name" value={deleteUserName} onChange={(e) => setDeleteUserName(e.target.value)} />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Delete stories"
                                                    name="deleteStories"
                                                    checked={deleteStoriesChecked}
                                                    onChange={(e) => setDeleteStoriesChecked(e.target.checked)}
                                                />
                                            </Form.Group>
                                            <Button variant="danger" type="submit">
                                                Delete
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className='display-6 d-flex justify-content-center mt-2'>{deleteMessage}</div>
                                </Row>
                            </>
                        }
                        {activeMenuKey === 'Parts' &&
                            <>
                                <Row className='mt-2'>
                                    <Col xs={3}>
                                        <Form onSubmit={(e) =>
                                            handleAddPartorProposal(e)}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Add part or proposal to branch ID</Form.Label>
                                                <div>Branch Id</div>
                                                <Form.Control type="number" placeholder="Enter ID" value={addPartBranchId} onChange={(e) => setAddPartBranchId(parseInt(e.target.value))} />
                                                User Id
                                                <Form.Control className='mt-1' type="number" placeholder="Enter user ID (0 = admin)" value={addPartUserId} onChange={(e) => setAddPartUserId(parseInt(e.target.value))} />
                                            </Form.Group>
                                            <div className="align-items-center mb-2">
                                                <ButtonGroup>
                                                    <ToggleButton
                                                        key={1}
                                                        id={`radio-1`}
                                                        type="radio"
                                                        variant='outline-write'
                                                        name="radio"
                                                        value={'part'}
                                                        checked={radioValue === 'part'}
                                                        onChange={(e) => { setRadioValue('part') }}
                                                    >
                                                        Part
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        key={2}
                                                        id={`radio-2`}
                                                        type="radio"
                                                        variant='outline-vote'
                                                        name="radio"
                                                        value={'proposal'}
                                                        checked={radioValue === 'proposal'}
                                                        onChange={(e) => { setRadioValue('proposal'); }}
                                                    >
                                                        Proposal
                                                    </ToggleButton>
                                                </ButtonGroup>
                                            </div>
                                            <div>
                                                <Button variant="primary" type="submit">
                                                    Add
                                                </Button>
                                                <Button className='ms-2' variant="secondary" type="button" onClick={() => handleFetchBranchInfo(addPartBranchId)}>
                                                    Fetch
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className='display-6 mt-2'>{deleteMessage}</div>
                                    </Col>
                                    <Col>
                                        <SlateRichTextEditor onSlateContentChange={handleSlateContentChange} initialValueProp={initialSlateContent} />
                                    </Col>
                                </Row>
                            </>
                        }
                        {activeMenuKey === 'New users' &&
                            <>
                                <Row className='mt-2'>
                                    {newUsers.length > 0 &&
                                        <div style={{ overflowY: "auto", maxHeight: "25rem" }}>
                                            <Table striped bordered hover responsive size='sm'>
                                                <thead>
                                                    <tr>
                                                        {Object.keys(newUsers[0]).reverse().map((reportKey) => (
                                                            <th key={reportKey}>{reportKey}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newUsers.map((user, index) => (
                                                        <tr>
                                                            {Object.keys(newUsers[0]).reverse().map((reportKey) => {
                                                                if (reportKey === "id") {
                                                                    return (<td key={user.id}><Button size='sm' onClick={() => handleNewUserIdClick(user.name)}>{user[reportKey]}</Button></td>)
                                                                } else {
                                                                    return (<td key={user.id}>{user[reportKey]}</td>)
                                                                }
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </Row>
                            </>
                        }

                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default AdminPage

