import React, { useEffect, useState } from 'react';
import { Button, Container, ListGroup } from 'react-bootstrap';
import { Bezier2, FilePlus, Trash } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { trackPromise } from 'react-promise-tracker';
import { Link, useLocation } from 'react-router-dom';
import './Components.css';
import { useUser } from './contexts/UserContext.tsx';
import LoadingIndicatorButton from './ressources/LoadingIndicatorButton.js';
import SlateReadOnly from './ressources/SlateReadOnly.js';
import SlateRichTextEditor from './ressources/SlateRichTextEditor.js';
import StoryAuthorsCard from './ressources/StoryAuthorsCard.js';
import StoryCard from './ressources/StoryCard.js';
import { VerticallyCenteredModal } from './ressources/VerticallyCenteredModal.js';
import { urls } from './ressources/urls.js';
import { countSlateObjectWords, postJson, shortenDate, trimSlateContentInPlace } from './ressources/utils.js';
import StoryBranch from './types/types.StoryBranch.ts';
import { GrayBackgroundBox } from './ressources/CustomStyles.js';
import { Tooltip } from 'react-tooltip';


export default function ContinuePage() {
    const location = useLocation();
    const [story, setStory] = useState<StoryBranch>(location.state ? location.state.story : null);
    const { user } = useUser();
    const [userProposal, setUserProposal] = useState<any | null>(null);
    const [alertState, setAlertState] = useState({ showAlert: false, variant: "success", message: "", heading: "", blockSubmission: false });
    const [parts, setParts] = useState([]);
    const [isEditing, setIsEditing] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [slateContent, setSlateContent] = useState(null);
    const [initialSlateContent, setInitialSlateContent] = useState(null);
    const [slateWordCount, setSlateWordCount] = useState(0);

    const handleSlateContentChange = (newContent) => {
        setSlateContent(newContent);
        setSlateWordCount(countSlateObjectWords(newContent));
    };

    useEffect(() => {
        if (!story?.is_data_complete) {
            postJson(urls.getStoryBranch, { story_id: story.id, branch_id: story.branch_id }).then(data => { setStory(data.story) });
        }
        else {
            postJson(urls.loadBranchPartsUrl, { story_id: story.id, branch_id: story.branch_id })
                .then(data => {
                    if (data.error) {
                        setAlertState({ showAlert: true, variant: "warning", message: data.error, heading: "You can't continue this story right now.", blockSubmission: true });
                    } else {
                        setUserProposal(data.user_proposal)
                        if (data.user_proposal) {
                            setIsEditing(false);
                            setInitialSlateContent(data.user_proposal.content)
                        }
                        setParts(data.parts);
                    }
                });
        }
    }, [story]);

    const handleSubmitDelete = async () => {
        if (userProposal == null) {
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ proposal_id: userProposal.id, story_id: story.id, branch_id: story.branch_id })
        };
        const response = await trackPromise(fetch(urls.baseUrl + urls.deleteProposalUrl, requestOptions));
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.error) {
            setAlertState({
                showAlert: true,
                variant: "danger",
                heading: "You can't delete your proposal right now.",
                message: data.error,
                blockSubmission: false,
            })
        } else {
            setModalShow(false);
            setUserProposal(null);
            setIsEditing(true);
            setInitialSlateContent(null);
            setAlertState({
                showAlert: true,
                variant: "success",
                heading: "All cleaned up!",
                message: "You successfully deleted your proposal.",
                blockSubmission: false,
            })
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userProposal && userProposal.content === slateContent) {
            setIsEditing(false);
            return;
        }
        setButtonDisabled(true);
        // Prepare request
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text: trimSlateContentInPlace(slateContent), story_id: story.id, branch_id: story.branch_id, story_proposal_update_id: userProposal ? userProposal.id : null })
        };

        // Send request
        try {
            const response = await trackPromise(fetch(urls.baseUrl + urls.saveTextUrl, requestOptions));
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.error) {
                setAlertState({
                    showAlert: true,
                    variant: "danger",
                    heading: "Can't submit proposal",
                    message: data.error,
                    blockSubmission: false
                })
            }
            else {
                setAlertState({
                    showAlert: true,
                    variant: "success",
                    heading: "Excellent!",
                    message: "You successfully submitted your proposal.",
                    blockSubmission: false
                })
                setIsEditing(false);
                setUserProposal(data.user_proposal);
                setInitialSlateContent(data.user_proposal.content);
            }

        } catch (error) {
            console.error('An error occurred while submitting the form:', error);
        }
        setButtonDisabled(false);
    };

    return (
        <div className="ContinuePage">
            <Container fluid className='pt-3'>
                <div>
                    {parts.length > 0 && story.is_data_complete &&
                        <Row>
                            <Col md={12} lg={2} className='mb-5'>
                                <StoryCard story={story} maxWidth='450px' />
                                {story.sibling_branches.length > 1 &&
                                    <div className='themed-text mt-3 mb-1'>Continue other Branches</div>
                                }
                                <ListGroup>
                                    {story.sibling_branches.map((sibling, index) => {
                                        console.log(story.sibling_branches, sibling, story.branch_id);
                                        if (sibling[0] !== story.branch_id) {
                                            return (
                                                <ListGroup.Item
                                                    onClick={() => setStory({ id: story.id, branch_id: sibling[0] })}
                                                    style={{ cursor: 'pointer' }}
                                                    variant='primary'
                                                    action
                                                    key={sibling[0]}
                                                >
                                                    <Bezier2 className='me-1' /><b>#{index} -</b> {shortenDate(sibling[1])}
                                                </ListGroup.Item>
                                            )
                                        }
                                    })}
                                </ListGroup>
                            </Col>
                            <Col lg={1} md={0}>
                            </Col>
                            <Col md={12} lg={6} style={{ maxWidth: "800px" }}>
                                {alertState.showAlert &&
                                    <div>
                                        <Alert variant={alertState.variant} onClose={() => setAlertState(prevState => ({ ...prevState, showAlert: false }))} dismissible>
                                            <Alert.Heading>{alertState.heading}</Alert.Heading>
                                            <p>
                                                {alertState.message}
                                            </p>
                                        </Alert>
                                    </div>
                                }
                                <div >
                                    <div className='display-6 mb-2 themed-text'><FilePlus className='me-1 mb-2 ' />{userProposal ? "Your proposal" : "Continue the story..."}</div>
                                    <Form method="post" onSubmit={handleSubmit}>
                                        <Form.Group controlId="continueForm.ControlTextarea1">
                                            {(userProposal != null && isEditing) || userProposal == null ?
                                                <SlateRichTextEditor onSlateContentChange={handleSlateContentChange} initialValueProp={initialSlateContent} />
                                                :
                                                <div style={{
                                                    overflowY: "auto",
                                                    maxHeight: "900px",
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", /* Subtle shadow instead of border */
                                                    padding: "10px",
                                                    fontSize: user?.font_size,
                                                }}>
                                                    <SlateReadOnly initialValue={initialSlateContent} />
                                                </div>
                                            }
                                            <Form.Text className="text-muted">
                                                Estimated words: {slateWordCount}
                                            </Form.Text>
                                            {userProposal == null ?
                                                <div className="d-flex justify-content-center my-2">
                                                    <Button className='mx-2' active={alertState.blockSubmission} disabled={buttonDisabled} variant="write" type="submit"
                                                        data-tooltip-id="submit-tooltip"
                                                        data-tooltip-content="You can still edit you propsal after submitting."
                                                        data-tooltip-variant="success"
                                                    >
                                                        <LoadingIndicatorButton />Submit
                                                    </Button>
                                                    <Tooltip id="submit-tooltip" />
                                                </div>
                                                :
                                                <div className="d-flex align-items-center justify-content-between my-2">
                                                    <div>
                                                        {!isEditing &&
                                                            <Button className='me-1' type='button' onClick={() => setIsEditing(true)}>Edit</Button>}
                                                        {isEditing && !buttonDisabled &&
                                                            <Button className='' variant="primary" type="submit"><LoadingIndicatorButton />Save</Button>
                                                        }
                                                    </div>
                                                    <div><Button className='mx-2' type='button' variant="outline-danger" onClick={() => setModalShow(true)}><Trash className='me-1' />Delete</Button></div>
                                                    <VerticallyCenteredModal
                                                        show={modalShow}
                                                        onHide={() => setModalShow(false)}
                                                        onConfirm={() => handleSubmitDelete()}
                                                        title="Permanent deletion"
                                                        body="Are you sure you want to delete your proposal permanently?"
                                                    />
                                                </div>
                                            }
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className='my-5'>
                                    <StoryAuthorsCard storyParts={parts} story={story} userIsCreator={false} />
                                </div>
                            </Col>
                            <Col lg={1} md={0}>
                            </Col>
                            <Col lg={2} md={12} className='mb-3'>
                                <div className={GrayBackgroundBox}>
                                    <h5>
                                        Writing guide
                                    </h5>
                                    <div><b>Submit: </b><span className='text-muted'>By submitting your proposal it is saved until the writing cycle ends and then presented to other writers as voting choice.
                                    </span></div>
                                    <div><b>Edit: </b><span className='text-muted'>After submittig you are free to edit your proposal any time before the writing cycle ends.
                                    </span></div>
                                    <div><b>Archived proposals: </b><span className='text-muted'>When the voting cycle ends and your proposal is not beneath the majority votes it will be archived. The Archived Proposals are available on your profile page.
                                    </span></div>
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
        </div>
    );
}

