import { Row, Col, Card, ListGroup } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import FlareTree from "./FlareTree";
import { shortenDate } from "./utils";


function StoryTreeAccordion({ story }) {
    const d3Container = useRef(null);
    const [nodeData, setNodeData] = useState({})

    const verticalSpacing = 80;

    const handleNodeHover = (nodeData) => {
        setNodeData(nodeData)
    };

    useEffect(() => {

        const tree = FlareTree(story["tree"], verticalSpacing,
            {
                // width: story["n_branches"] * 150,
                height: (story["n_main_branch_parts"] - 0.5) * verticalSpacing,
                label: d => d.creator_name,
                fill: d => d.data.fill,
                stroke: d => d.data.stroke,
                link: d => `https://cornuvotia.net/story/${story["id"]}/${d.branch_id}`,
                onNodeHover: handleNodeHover,
            })
        d3Container.current.innerHTML = '';
        d3Container.current.appendChild(tree);

    }, []);



    return (

        <Row>
            <Col xs={12} lg={10}>
                <div style={{ overflow: 'auto' }}>
                    <div ref={d3Container} style={{ maxHeight: "600px" }}></div>
                </div>
            </Col>
            <Col xs={12} lg={2}>
                <Card style={{ marginTop: '20px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', color: 'black' }}>
                    <Card.Body>
                        <Card.Title>Part {nodeData.nth}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">by {nodeData.creator_name}</Card.Subtitle>
                        <ListGroup variant="flush">
                            <ListGroup.Item>Creation Date: {shortenDate(nodeData.creation_date)}</ListGroup.Item>
                            <ListGroup.Item>Token Count: {nodeData.n_tokens}</ListGroup.Item>
                            <ListGroup.Item>Branch ID: {nodeData.branch_id}</ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default StoryTreeAccordion;