import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';

const CountdownTimer = ({ minutes = 1 }) => {
    const [time, setTime] = useState(minutes * 60);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval = null;

        if (isActive && time > 0) {
            interval = setInterval(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else if (time === 0) {
            setIsActive(false);
        }

        return () => clearInterval(interval);
    }, [isActive, time]);

    useEffect(() => {
        setTime(minutes * 60);
    }, [minutes]);

    const toggleStart = () => {
        setIsActive(!isActive);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    return (
        <Container className="d-flex justify-content-center align-items-center countdown-container">
            <div className="timer-display themed-text">
                <div >Challenge yourself with your set writing time</div>
                <div className="timer-text fw-bold" style={{ fontSize: '1.5rem' }}>{formatTime(time)}</div>
                <Button onClick={toggleStart} variant="outline-secondary" className="start-button themed-text me-1">
                    {isActive ? 'Pause' : 'Start'}
                </Button>
                <Button onClick={() => { setIsActive(false); setTime(minutes * 60) }} variant="outline-secondary" className="start-button themed-text">
                    Reset
                </Button>
            </div>
        </Container>
    );
};

export default CountdownTimer;