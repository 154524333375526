function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const importImagesFromDirectory = (directory) => {
    switch (directory) {
        case 'storybanner':
            return importAll(require.context('../../images/storybanner', false, /\.(svg|png|jpe?g)$/));
        case 'avatars':
            return importAll(require.context('../../images/avatars', false, /\.(svg|png|jpe?g)$/));
        case 'backgrounds':
            return importAll(require.context('../../images/backgrounds', false, /\.(svg|png|jpe?g)$/));
        case 'icons':
            return importAll(require.context('../../images/icons', false, /\.(png|jpe?g|svg)$/));
        case 'achievements':
            return importAll(require.context('../../images/achievements', false, /\.(png|jpe?g|svg)$/));
        default:
            throw new Error(`Unexpected directory ${directory}`);
    }
};

const directories = ['storybanner', 'avatars', 'backgrounds', 'icons', 'achievements'];
const image_provider = {};

directories.forEach((dir) => {
    image_provider[dir] = importImagesFromDirectory(dir);
});

export { image_provider as ImageProvider }